@charset "UTF-8";
html {
  font-size: 100%;
  line-height: 1.5em;
}
@media print {
  html {
    font-size: 12pt;
  }
}

html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  outline: none;
  color: #000;
  text-decoration: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-family: "Merriweather", serif;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

h1, h2, h3 {
  margin: 0;
}

h1 {
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 48px;
  line-height: 3rem;
  font-weight: 700;
  font-family: "Oswald", sans-serif;
  margin-bottom: 72px;
  margin-bottom: 4.5rem;
  margin-top: 24px;
  margin-top: 1.5rem;
}

h2 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 36px;
  line-height: 2.25rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  margin-top: 24px;
  margin-top: 1.5rem;
}
h2 + .chapo {
  margin-top: 0px;
  margin-top: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

p + h2 {
  margin-top: 48px;
  margin-top: 3rem;
}

h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 36px;
  line-height: 2.25rem;
  font-weight: 700;
  font-family: "Merriweather", serif;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: 24px;
  margin-top: 1.5rem;
}
.page__content h3, .page__block h3 {
  text-transform: uppercase;
  line-height: 2rem;
}
.page__block h3 {
  padding: 0px 120px;
  padding: 0 7.5rem;
}
@media (max-width: 73.24em) {
  .page__block h3 {
    padding: 0px 60px;
    padding: 0 3.75rem;
  }
}
@media (max-width: 60.74em) {
  .page__block h3 {
    padding: 0px;
    padding: 0;
  }
}

p + h3 {
  margin-top: 48px;
  margin-top: 3rem;
}

.page__content ul, .page__block ul {
  font-weight: 300;
  line-height: 30px;
  line-height: 1.875rem;
  margin-top: 0px;
  margin-top: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

main p, main ul.unstyled-list, main ul:not([class]), main td {
  margin-top: 0px;
  margin-top: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}
main p strong, main ul.unstyled-list strong, main ul:not([class]) strong, main td strong {
  font-weight: bold;
}
main p a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button), main ul.unstyled-list a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button), main ul:not([class]) a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button), main td a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  position: relative;
  z-index: 1;
  text-decoration: none;
  border-bottom: 1px solid #50E3C2;
  color: white;
  transition: all 0.15s ease-in-out;
  		/*&:after {
  			@include transition(all 0.15s ease-in-out);
  			content: "";
  			display: block;
  			position: absolute;
  			left: 0;
  			right: 0;
  			bottom: 0;
  			height: 1px;
  			background: $color-gray;
  			z-index: -1;
  		}
  
  		&:hover {
  			&:after {
  				bottom: 0;
  				background: $color-main;
  				height: 100%;
  			}
  		}*/
}
main p a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover, main ul.unstyled-list a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover, main ul:not([class]) a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover, main td a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: inset 0 -30px #50E3C2;
  color: #000F24;
}

h3 a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #50E3C2;
  transition: all 0.15s ease-in-out;
}
h3 a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: inset 0 -30px #50E3C2;
}

hr {
  border: 0;
  height: 1px;
  background-color: #50E3C2;
  margin: 120px 0px 48px 0px;
  margin: 7.5rem 0 3rem 0;
}
hr:before {
  content: "";
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  top: -3px;
  position: relative;
}
hr.invisible {
  height: 0;
  border: 0;
}

figure {
  margin: 0;
}
figure figcaption {
  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: block;
  margin-top: 6px;
  margin-top: 0.375rem;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}
figure img {
  display: block;
}

.chapo {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", serif;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  font-weight: 900;
}

h1 + .chapo {
  margin-top: -48px;
  margin-top: -3rem;
  margin-bottom: 96px;
  margin-bottom: 6rem;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 400;
}

.small-text, .wys-small-text {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}

.form p {
  margin: 0px;
  margin: 0;
}
.form .form-group {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.form .form-group ul {
  list-style-type: none;
  clear: both;
  padding: 0;
}
.form input[type="submit"] {
  width: 100%;
  display: inline-block;
}
.form label {
  text-align: left;
  display: block;
  vertical-align: top;
  float: left;
  width: 80%;
  padding: 6px 24px 6px 0px;
  padding: 0.375rem 1.5rem 0.375rem 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.form span.help-block {
  float: right;
}
.form .help-block {
  font-size: 12px;
  font-size: 0.75rem;
}
.has-error .form .help-block {
  color: #FF203C;
}
.form .has-error .help-block, .form .has-error label {
  color: #FF203C;
}
.form .has-error input[type="text"], .form .has-error input[type="email"], .form .has-error input[type="password"], .form .has-error input[type="url"], .form .has-error textarea, .form .has-error select {
  border-color: #FF203C;
}
.form .form-actions {
  margin-top: 96px;
  margin-top: 6rem;
}
.form input[type="text"], .form input[type="email"], .form input[type="password"], .form input[type="url"], .form textarea {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  display: block;
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: 0;
  border-bottom: 1px solid white;
  width: 100%;
  color: white;
  background: rgba(80, 227, 194, 0.05);
}
.form input[type="text"]:focus, .form input[type="email"]:focus, .form input[type="password"]:focus, .form input[type="url"]:focus, .form textarea:focus {
  border-color: #50E3C2;
}
@media (max-width: 46.99em) {
  .form input[type="text"], .form input[type="email"], .form input[type="password"], .form input[type="url"], .form textarea {
    width: 100%;
  }
}
.form select {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 28px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid white;
  outline: 0;
  color: white;
  -webkit-appearance: none;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#FFFFFF" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.form select:focus {
  border-bottom: 1px solid #50E3C2;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#50E3C2" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.form select::-ms-expand {
  display: none;
}

.form-errors {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
}
@media (max-width: 46.99em) {
  .form-errors {
    padding-left: 0;
  }
}

.account-form fieldset {
  border: 0px;
  border-bottom: 0px solid transparent;
}
.account-form p {
  margin: 0px;
  margin: 0;
}
.account-form p a:hover {
  box-shadow: none;
}
.account-form .form-group {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.account-form .form-group ul {
  list-style-type: none;
  clear: both;
  padding: 0;
}
.account-form input[type="submit"] {
  width: 50%;
  display: inline-block;
  background: black;
  color: white;
  font-family: "Oswald";
  text-align: center;
}
.account-form label {
  text-align: left;
  display: block;
  vertical-align: top;
  float: left;
  width: 60%;
  padding: 6px 24px 6px 0px;
  padding: 0.375rem 1.5rem 0.375rem 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Helvetica";
  font-weight: small;
  text-transform: capitalize;
  color: #000f24;
}
.account-form span.help-block {
  float: right;
  color: red;
}
.account-form .help-block {
  font-size: 12px;
  font-size: 0.75rem;
}
.has-error .account-form .help-block {
  color: #FF203C;
}
.account-form .has-error .help-block, .account-form .has-error label {
  color: #FF203C;
}
.account-form .has-error input[type="text"], .account-form .has-error input[type="email"], .account-form .has-error input[type="password"], .account-form .has-error input[type="url"], .account-form .has-error textarea, .account-form .has-error select {
  border-color: #FF203C;
}
.account-form .form-actions {
  margin-top: 96px;
  margin-top: 6rem;
}
.account-form input[type="text"], .account-form input[type="email"], .account-form input[type="password"], .account-form input[type="url"], .account-form textarea {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  display: block;
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  width: 100%;
  color: black;
  font-family: "Helvetica";
  background: white;
}
.account-form input[type="text"]:focus, .account-form input[type="email"]:focus, .account-form input[type="password"]:focus, .account-form input[type="url"]:focus, .account-form textarea:focus {
  border-color: transparent;
  border-color: transparent;
}
@media (max-width: 46.99em) {
  .account-form input[type="text"], .account-form input[type="email"], .account-form input[type="password"], .account-form input[type="url"], .account-form textarea {
    width: 100%;
  }
}
.account-form select {
  font-size: 16px;
  font-size: 1rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 28px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  outline: 0;
  color: red;
  -webkit-appearance: none;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#FFFFFF" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.account-form select:focus {
  border-bottom: 1px solid transparent;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#50E3C2" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.account-form select::-ms-expand {
  display: none;
}

.form-errors {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
}
@media (max-width: 46.99em) {
  .form-errors {
    padding-left: 0;
  }
}

.account-horizontal-form fieldset {
  border: 0px;
  border-bottom: 0px solid transparent;
}
.account-horizontal-form p {
  margin: 0px;
  margin: 0;
}
.account-horizontal-form p a:hover {
  box-shadow: none;
}
.account-horizontal-form .form-group {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.account-horizontal-form .form-group ul {
  list-style-type: none;
  clear: both;
  padding: 0;
}
.account-horizontal-form input[type="submit"] {
  width: 50%;
  display: inline-block;
  background: black;
  color: white;
  font-family: "Oswald";
  text-align: center;
}
.account-horizontal-form label {
  text-align: left;
  display: block;
  vertical-align: top;
  float: left;
  width: 50%;
  padding: 6px 24px 6px 0px;
  padding: 0.375rem 1.5rem 0.375rem 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Helvetica";
  font-weight: bold;
  text-transform: capitalize;
  color: #000f24;
}
.account-horizontal-form span.help-block {
  float: right;
  color: red;
  display: block !important;
}
@media screen and (max-width: 780px) {
  .account-horizontal-form span.help-block {
    display: none !important;
  }
}
.account-horizontal-form .help-block {
  font-size: 12px;
  font-size: 0.75rem;
}
.has-error .account-horizontal-form .help-block {
  color: #FF203C;
}
.account-horizontal-form .has-error .help-block, .account-horizontal-form .has-error label {
  color: #FF203C;
}
.account-horizontal-form .has-error input[type="text"], .account-horizontal-form .has-error input[type="email"], .account-horizontal-form .has-error input[type="password"], .account-horizontal-form .has-error input[type="url"], .account-horizontal-form .has-error textarea, .account-horizontal-form .has-error select {
  border-color: #FF203C;
}
.account-horizontal-form .form-actions {
  margin-top: 96px;
  margin-top: 6rem;
}
.account-horizontal-form input[type="text"], .account-horizontal-form input[type="email"], .account-horizontal-form input[type="password"], .account-horizontal-form input[type="url"], .account-horizontal-form textarea {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  display: block;
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  width: 50%;
  color: black;
  font-family: "Helvetica";
  background: white;
}
.account-horizontal-form input[type="text"]:focus, .account-horizontal-form input[type="email"]:focus, .account-horizontal-form input[type="password"]:focus, .account-horizontal-form input[type="url"]:focus, .account-horizontal-form textarea:focus {
  border-color: transparent;
  border-color: transparent;
}
@media (max-width: 46.99em) {
  .account-horizontal-form input[type="text"], .account-horizontal-form input[type="email"], .account-horizontal-form input[type="password"], .account-horizontal-form input[type="url"], .account-horizontal-form textarea {
    width: 50%;
  }
}
.account-horizontal-form select {
  font-size: 16px;
  font-size: 1rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 28px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  outline: 0;
  color: red;
  -webkit-appearance: none;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#FFFFFF" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.account-horizontal-form select:focus {
  border-bottom: 1px solid transparent;
  background-color: rgba(80, 227, 194, 0.05);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#50E3C2" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.account-horizontal-form select::-ms-expand {
  display: none;
}

.form-errors {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
}
@media (max-width: 46.99em) {
  .form-errors {
    padding-left: 0;
  }
}

.ict-project-form {
  background: white;
}
.ict-project-form fieldset {
  border: 1px solid black;
}
.ict-project-form p {
  margin: 0px;
  margin: 0;
}
.ict-project-form p a:hover {
  box-shadow: none;
}
.ict-project-form p label.control-label {
  display: inline-block;
  background: transparent;
  color: #010c1b;
  font-size: 15px;
}
.ict-project-form .form-group {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.ict-project-form .form-group ul {
  list-style-type: none;
  clear: both;
  padding: 0;
}
.ict-project-form input[type="submit"] {
  width: 50%;
  display: inline-block;
  background: black;
  color: white;
  font-family: "Oswald";
  text-align: center;
}
.ict-project-form h1, .ict-project-form h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  color: black;
}
.ict-project-form h5 {
  font-size: 18px;
  line-height: 2.25rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  color: black;
}
.ict-project-form label {
  text-align: left;
  display: block;
  vertical-align: top;
  float: left;
  width: 100%;
  padding: 6px 24px 6px 0px;
  padding: 0.375rem 1.5rem 0.375rem 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Helvetica";
  font-weight: small;
  text-transform: capitalize;
  color: #010c1b;
}
.ict-project-form span.help-block {
  float: left;
  color: red;
  width: 100%;
  display: block;
}
.ict-project-form span.help-block a {
  color: black !important;
}
.ict-project-form .help-block {
  font-size: 12px;
  font-size: 0.75rem;
}
.has-error .ict-project-form .help-block {
  color: #FF203C;
}
.ict-project-form .has-error .help-block, .ict-project-form .has-error label {
  color: #FF203C;
}
.ict-project-form .has-error input[type="text"], .ict-project-form .has-error input[type="email"], .ict-project-form .has-error input[type="password"], .ict-project-form .has-error input[type="url"], .ict-project-form .has-error textarea, .ict-project-form .has-error select {
  border: 1px solid black;
}
.ict-project-form .form-actions {
  margin-top: 96px;
  margin-top: 6rem;
}
.ict-project-form input[type="text"], .ict-project-form input[type="email"], .ict-project-form input[type="password"], .ict-project-form input[type="url"], .ict-project-form textarea {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  display: block;
  transition: all 0.25s ease-in-out;
  outline: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  width: 100%;
  color: black;
  font-family: "Helvetica";
  background: white;
  border: 1px solid black;
}
.ict-project-form input[type="text"]:focus, .ict-project-form input[type="email"]:focus, .ict-project-form input[type="password"]:focus, .ict-project-form input[type="url"]:focus, .ict-project-form textarea:focus {
  border: 1px solid black;
}
@media (max-width: 46.99em) {
  .ict-project-form input[type="text"], .ict-project-form input[type="email"], .ict-project-form input[type="password"], .ict-project-form input[type="url"], .ict-project-form textarea {
    width: 100%;
  }
}
.ict-project-form select {
  font-size: 16px;
  font-size: 1rem;
  padding: 6px 6px;
  padding: 0.375rem 0.375rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 28px;
  border-radius: 1;
  border: 1;
  border: 1px solid white;
  outline: 1;
  color: black;
  -webkit-appearance: none;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#FFFFFF" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.ict-project-form select:focus {
  border: 1px solid white;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#50E3C2" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}
.ict-project-form select::-ms-expand {
  display: none;
}

main .ict-project-form input[type="file"] {
  background: transparent;
  font-size: 12px;
  color: black;
  font-family: "Merriweather", serif;
}

main .ict-project-form p.link-item-form a {
  background: transparent;
  color: black !important;
  box-shadow: inset 0 0px transparent !important;
  border-bottom: 3px solid #50E3C2 !important;
  font-weight: bold !important;
}
main .ict-project-form p.link-item-form a:hover {
  background: transparent;
  color: black !important;
  box-shadow: inset 0 0px transparent !important;
  border-bottom: 3px solid #50E3C2 !important;
  font-weight: bold !important;
}

main .ict-project-form p.ict-project-style__field-style a {
  background: transparent;
  color: black !important;
  box-shadow: inset 0 0px transparent !important;
  border-bottom: 3px solid #50E3C2 !important;
  font-weight: bold !important;
  font-size: 13px;
  display: inline-block;
}

main .ict-project-form p.keywords-field {
  margin-left: 0 !important;
  width: auto !important;
}
main .ict-project-form p.keywords-field a {
  background: #50E3C2;
  color: black !important;
  border: 1px solid #010c1b;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  border-bottom: 1px solid #010c1b !important;
  display: inline-block;
  transition: none !important;
  font-size: 13px;
}
main .ict-project-form p.keywords-field a:hover {
  background: white;
  color: black !important;
  border: 1px solid #50E3C2;
  display: inline-block;
  border-bottom: 0px !important;
  box-shadow: inset 0 0px #50E3C2 !important;
  border-bottom: 1px solid #50E3C2 !important;
  font-size: 13px;
}

main .ict-project-form p.select_style select {
  border: 1px solid #010c1b;
}
main .ict-project-form p.select_style select:focus {
  border: 1px solid #010c1b;
}

main .ict-project-form p.error_field_style input, main .ict-project-form p.error_field_style textarea, main .ict-project-form p.error_field_style select {
  border-color: red !important;
  border: 1px solid red !important;
  box-shadow: inset 0 0px red !important;
}
main .ict-project-form p.error_field_style input:focus, main .ict-project-form p.error_field_style textarea:focus, main .ict-project-form p.error_field_style select:focus {
  border: 1px solid red !important;
}

.form-errors {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
}
@media (max-width: 46.99em) {
  .form-errors {
    padding-left: 0;
  }
}

.button, .form input[type="submit"], .account-form input[type="submit"], .account-horizontal-form input[type="submit"], .ict-project-form input[type="submit"], .wys-button, .wys-button-small, .wys-button-xsmall, .wys-button-black, .wys-button-black-small, .wys-button-black-xsmall, .wys-button-white, .wys-button-white-small, .wys-button-white-xsmall, .brief-box__button {
  border: 0;
  outline: 0;
  background: none;
  box-sizing: border-box;
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  padding: 6px 24px 6px 24px;
  padding: 0.375rem 1.5rem 0.375rem 1.5rem;
  font-weight: 300;
  background: #50E3C2;
  color: #1D1D1B;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.button i, .form input[type="submit"] i, .account-form input[type="submit"] i, .account-horizontal-form input[type="submit"] i, .ict-project-form input[type="submit"] i, .wys-button i, .wys-button-small i, .wys-button-xsmall i, .wys-button-black i, .wys-button-black-small i, .wys-button-black-xsmall i, .wys-button-white i, .wys-button-white-small i, .wys-button-white-xsmall i, .brief-box__button i {
  margin-right: 6px;
  margin-right: 0.375rem;
}
.button:hover, .form input[type="submit"]:hover, .account-form input[type="submit"]:hover, .account-horizontal-form input[type="submit"]:hover, .ict-project-form input[type="submit"]:hover, .wys-button:hover, .wys-button-small:hover, .wys-button-xsmall:hover, .wys-button-black:hover, .wys-button-black-small:hover, .wys-button-black-xsmall:hover, .wys-button-white:hover, .wys-button-white-small:hover, .wys-button-white-xsmall:hover, .brief-box__button:hover {
  background: #1D1D1B;
  color: white;
}
.button:hover a, .form input[type="submit"]:hover a, .account-form input[type="submit"]:hover a, .account-horizontal-form input[type="submit"]:hover a, .ict-project-form input[type="submit"]:hover a, .wys-button:hover a, .wys-button-small:hover a, .wys-button-xsmall:hover a, .wys-button-black:hover a, .wys-button-black-small:hover a, .wys-button-black-xsmall:hover a, .wys-button-white:hover a, .wys-button-white-small:hover a, .wys-button-white-xsmall:hover a, .brief-box__button:hover a {
  color: white;
}
.button--black, .wys-button-black, .wys-button-black-small, .wys-button-black-xsmall, .brief-box--yellow .brief-box__button {
  background-color: #1D1D1B;
  color: white;
}
.button--black:hover, .wys-button-black:hover, .wys-button-black-small:hover, .wys-button-black-xsmall:hover, .brief-box--yellow .brief-box__button:hover {
  background-color: #50E3C2;
  color: #1D1D1B;
}
.button--white, .wys-button-white, .wys-button-white-small, .wys-button-white-xsmall {
  background-color: white;
  color: #1D1D1B;
  border: 1px solid #1D1D1B;
}
.button--white:hover, .wys-button-white:hover, .wys-button-white-small:hover, .wys-button-white-xsmall:hover {
  background-color: #1D1D1B;
  color: white;
}
.button--accent {
  background-color: #FF203C;
  color: white;
  border: 1px solid #FF203C;
}
.button--accent:hover {
  background-color: white;
  color: #FF203C;
  border: 1px solid white;
}
.button--small, .wys-button-small, .wys-button-black-small, .wys-button-white-small, .brief-box__button {
  font-size: 16px;
  font-size: 1rem;
  padding: 4.32px 24px;
  padding: 0.27rem 1.5rem;
}
.button--xsmall, .wys-button-xsmall, .wys-button-black-xsmall, .wys-button-white-xsmall {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 3px 12px;
  padding: 0.1875rem 0.75rem;
}
.button--block {
  display: block;
  text-align: center;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
}
.button--tournees {
  color: white;
  line-height: 24px;
  line-height: 1.5rem;
  padding: 6px 12px 6px 12px;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  background: #1D1D1B;
  text-transform: uppercase;
  font-weight: 400;
  border: 1px solid #1D1D1B;
}
.button--tournees:hover {
  background-color: #50E3C2;
  color: #1D1D1B;
  border: 1px solid #50E3C2;
}

.m0 {
  margin: 0px;
  margin: 0;
}

.mt0 {
  margin-top: 0px;
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0px;
  margin-bottom: 0;
}

.mr0 {
  margin-right: 0px;
  margin-right: 0;
}

.m1 {
  margin: 24px;
  margin: 1.5rem;
}

.mt1 {
  margin-top: 24px;
  margin-top: 1.5rem;
}

.mb1 {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.mr1 {
  margin-right: 24px;
  margin-right: 1.5rem;
}

.ml1 {
  margin-left: 24px;
  margin-left: 1.5rem;
}

.mt2 {
  margin-top: 48px;
  margin-top: 3rem;
}

.mb2 {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.mr2 {
  margin-right: 48px;
  margin-right: 3rem;
}

.ml2 {
  margin-left: 48px;
  margin-left: 3rem;
}

.mt3 {
  margin-top: 72px;
  margin-top: 4.5rem;
}

.mb3 {
  margin-bottom: 72px;
  margin-bottom: 4.5rem;
}

.mr3 {
  margin-right: 72px;
  margin-right: 4.5rem;
}

.ml3 {
  margin-left: 72px;
  margin-left: 4.5rem;
}

.mt4 {
  margin-top: 96px;
  margin-top: 6rem;
}

.mb4 {
  margin-bottom: 96px;
  margin-bottom: 6rem;
}

.mr4 {
  margin-right: 96px;
  margin-right: 6rem;
}

.ml4 {
  margin-left: 96px;
  margin-left: 6rem;
}

.p1 {
  padding: 24px;
  padding: 1.5rem;
}

.pt1 {
  padding-top: 24px;
  padding-top: 1.5rem;
}

.pb1 {
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
}

.pr1 {
  padding-right: 24px;
  padding-right: 1.5rem;
}

.pl1 {
  padding-left: 24px;
  padding-left: 1.5rem;
}

.p2 {
  padding: 48px;
  padding: 3rem;
}

.pt2 {
  padding-top: 48px;
  padding-top: 3rem;
}

.pb2 {
  padding-bottom: 48px;
  padding-bottom: 3rem;
}

.pr2 {
  padding-right: 48px;
  padding-right: 3rem;
}

.pl2 {
  padding-left: 48px;
  padding-left: 3rem;
}

.bg {
  background-color: #50E3C2;
}

.tal {
  text-align: left;
}

@media all {
  .tal-xxs {
    text-align: left;
  }
}

@media (min-width: 47em) {
  .tal-xs {
    text-align: left;
  }
}

@media (min-width: 60.75em) {
  .tal-sm {
    text-align: left;
  }
}

@media (min-width: 73.25em) {
  .tal-md {
    text-align: left;
  }
}

@media (min-width: 90em) {
  .tal-lg {
    text-align: left;
  }
}

.tac {
  text-align: center;
}

@media all {
  .tac-xxs {
    text-align: center;
  }
}

@media (min-width: 47em) {
  .tac-xs {
    text-align: center;
  }
}

@media (min-width: 60.75em) {
  .tac-sm {
    text-align: center;
  }
}

@media (min-width: 73.25em) {
  .tac-md {
    text-align: center;
  }
}

@media (min-width: 90em) {
  .tac-lg {
    text-align: center;
  }
}

.tar {
  text-align: right;
}

@media all {
  .tar-xxs {
    text-align: right;
  }
}

@media (min-width: 47em) {
  .tar-xs {
    text-align: right;
  }
}

@media (min-width: 60.75em) {
  .tar-sm {
    text-align: right;
  }
}

@media (min-width: 73.25em) {
  .tar-md {
    text-align: right;
  }
}

@media (min-width: 90em) {
  .tar-lg {
    text-align: right;
  }
}

.hide {
  display: none;
}

@media (max-width: 46.99em) {
  .hide-xxs {
    display: none;
  }
}

@media (max-width: 60.74em) {
  .hide-xs {
    display: none;
  }
}

@media (max-width: 73.24em) {
  .hide-sm {
    display: none;
  }
}

@media (max-width: 89.99em) {
  .hide-md {
    display: none;
  }
}

@media (min-width: 90em) {
  .hide-lg {
    display: none;
  }
}

.ffss {
  font-family: "Oswald", sans-serif;
}

.ffs {
  font-family: "Merriweather", serif;
}

.fsxxxl {
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 3rem !important;
}

.fsxxl {
  font-size: 30px;
  font-size: 1.875rem;
}

.fsxl {
  font-size: 25px;
  font-size: 1.5625rem;
}

.fsl {
  font-size: 20px;
  font-size: 1.25rem;
}

.fsm {
  font-size: 16px;
  font-size: 1rem;
}

.fss {
  font-size: 14px;
  font-size: 0.875rem;
}

.fsxs {
  font-size: 12px;
  font-size: 0.75rem;
}

.fwl {
  font-weight: 300;
}

.lh1 {
  line-height: 24px;
  line-height: 1.5rem;
}

.lh5 {
  line-height: 36px;
  line-height: 2.25rem;
}

.lh2 {
  line-height: 48px;
  line-height: 3rem;
}

.fcl {
  color: white;
}

.fcb {
  color: #000F24;
}

.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.white-bg {
  background: white;
}

@media (min-width: 47em) {
  .hide-from-xs {
    display: none !important;
  }
}

@media (max-width: 46.99em) {
  .hide-until-xs {
    display: none !important;
  }
}

@media (min-width: 60.75em) {
  .hide-from-sm {
    display: none !important;
  }
}

@media (max-width: 60.74em) {
  .hide-until-sm {
    display: none !important;
  }
}

@media (max-width: 60.74em) {
  .fsl-until-sm {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.wys-button {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button {
  color: #1D1D1B;
}
.page__block--black .wys-button:hover {
  color: white;
}

.wys-button-small {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-small {
  color: #1D1D1B;
}
.page__block--black .wys-button-small:hover {
  color: white;
}

.wys-button-xsmall {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-xsmall {
  color: #1D1D1B;
}
.page__block--black .wys-button-xsmall:hover {
  color: white;
}

.wys-button-black {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-black {
  color: white;
}
.page__block--black .wys-button-black:hover {
  color: #1D1D1B;
}

.wys-button-black-small {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-black-small {
  color: white;
}
.page__block--black .wys-button-black-small:hover {
  color: #1D1D1B;
}

.wys-button-black-xsmall {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-black-xsmall {
  color: white;
}
.page__block--black .wys-button-black-xsmall:hover {
  color: #1D1D1B;
}

.wys-button-white {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-white {
  color: #1D1D1B;
}
.page__block--black .wys-button-white:hover {
  color: white;
}

.wys-button-white-small {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-white-small {
  color: #1D1D1B;
}
.page__block--black .wys-button-white-small:hover {
  color: white;
}

.wys-button-white-xsmall {
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
}
.page__block--black .wys-button-white-xsmall {
  color: #1D1D1B;
}
.page__block--black .wys-button-white-xsmall:hover {
  color: white;
}

.wys-highlighted-paragraph {
  padding-left: 24px;
  padding-left: 1.5rem;
  margin-top: 36px;
  margin-top: 2.25rem;
  border-left: 1px solid #50E3C2;
}
.wys-highlighted-paragraph + .wys-highlighted-paragraph {
  margin-top: -24px;
  margin-top: -1.5rem;
  padding-top: 24px;
  padding-top: 1.5rem;
}
.page__block--yellow .wys-highlighted-paragraph {
  border-color: #000F24;
}

.wys-unstyled-link {
  border-bottom: 0 !important;
}
.wys-unstyled-link:hover {
  box-shadow: none !important;
}

.main {
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  padding: 0px 0px 0px 0px;
  padding: 0 0 0 0;
}
@media screen and (max-height: 900px) {
  .main {
    margin: 0px 0px 0px 0px;
    margin: 0 0 0 0;
  }
}

#container {
  margin-top: 57px;
}
@media (max-width: 89.99em) {
  #container {
    margin-top: 48px;
  }
}
@media (max-width: 60.74em) {
  #container {
    margin-top: 70px;
  }
}
@media (max-width: 46.99em) {
  #container {
    margin-top: 70px;
  }
}

html, body {
  background: #000F24;
  color: white;
}
@media (max-width: 60.74em) {
  html, body {
    overflow-x: hidden;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
}

/* Base container properties */
.container, .container-fluid, .container-full, .container-fixed {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 1172px) {
  .container, .container-fluid, .container-full, .container-fixed {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Base column properties */
.col-xxs-0, .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12, .col-xxs-13, .col-xxs-14, .col-xxs-15, .col-xxs-16, .col-xs-0, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-sm-0, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-md-0, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-lg-0, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16 {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 1px;
  /*float: left;*/
  display: inline-block;
  vertical-align: top;
}

/* Set box-sizing */
.container,
.row,
.row:after,
.row:before {
  box-sizing: border-box;
}

/* Container */
.container {
  max-width: 972px;
}
.container-full {
  padding-left: 0;
  padding-right: 0;
}
.container-fixed {
  width: 1000px;
}
@media screen and (min-width: 1172px) {
  .container {
    max-width: 1172px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

/* Clearfix & box-model + list-style reset for ul support */
.row {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  margin-left: -10px;
  margin-right: -10px;
  /* Nested grid */
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.row > .row {
  clear: none;
  float: left;
  margin: 0 !important;
}

/* Generate columns */
@media screen and (min-width: 0px) {
  .col-xxs-0 {
    width: 0%;
  }

  .col-xxs-push-0 {
    left: 0%;
  }

  .col-xxs-pull-0 {
    right: 0%;
  }

  .col-xxs-offset-0 {
    margin-left: 0%;
  }

  .col-xxs-1 {
    width: 6.25%;
  }

  .col-xxs-push-1 {
    left: 6.25%;
  }

  .col-xxs-pull-1 {
    right: 6.25%;
  }

  .col-xxs-offset-1 {
    margin-left: 6.25%;
  }

  .col-xxs-2 {
    width: 12.5%;
  }

  .col-xxs-push-2 {
    left: 12.5%;
  }

  .col-xxs-pull-2 {
    right: 12.5%;
  }

  .col-xxs-offset-2 {
    margin-left: 12.5%;
  }

  .col-xxs-3 {
    width: 18.75%;
  }

  .col-xxs-push-3 {
    left: 18.75%;
  }

  .col-xxs-pull-3 {
    right: 18.75%;
  }

  .col-xxs-offset-3 {
    margin-left: 18.75%;
  }

  .col-xxs-4 {
    width: 25%;
  }

  .col-xxs-push-4 {
    left: 25%;
  }

  .col-xxs-pull-4 {
    right: 25%;
  }

  .col-xxs-offset-4 {
    margin-left: 25%;
  }

  .col-xxs-5 {
    width: 31.25%;
  }

  .col-xxs-push-5 {
    left: 31.25%;
  }

  .col-xxs-pull-5 {
    right: 31.25%;
  }

  .col-xxs-offset-5 {
    margin-left: 31.25%;
  }

  .col-xxs-6 {
    width: 37.5%;
  }

  .col-xxs-push-6 {
    left: 37.5%;
  }

  .col-xxs-pull-6 {
    right: 37.5%;
  }

  .col-xxs-offset-6 {
    margin-left: 37.5%;
  }

  .col-xxs-7 {
    width: 43.75%;
  }

  .col-xxs-push-7 {
    left: 43.75%;
  }

  .col-xxs-pull-7 {
    right: 43.75%;
  }

  .col-xxs-offset-7 {
    margin-left: 43.75%;
  }

  .col-xxs-8 {
    width: 50%;
  }

  .col-xxs-push-8 {
    left: 50%;
  }

  .col-xxs-pull-8 {
    right: 50%;
  }

  .col-xxs-offset-8 {
    margin-left: 50%;
  }

  .col-xxs-9 {
    width: 56.25%;
  }

  .col-xxs-push-9 {
    left: 56.25%;
  }

  .col-xxs-pull-9 {
    right: 56.25%;
  }

  .col-xxs-offset-9 {
    margin-left: 56.25%;
  }

  .col-xxs-10 {
    width: 62.5%;
  }

  .col-xxs-push-10 {
    left: 62.5%;
  }

  .col-xxs-pull-10 {
    right: 62.5%;
  }

  .col-xxs-offset-10 {
    margin-left: 62.5%;
  }

  .col-xxs-11 {
    width: 68.75%;
  }

  .col-xxs-push-11 {
    left: 68.75%;
  }

  .col-xxs-pull-11 {
    right: 68.75%;
  }

  .col-xxs-offset-11 {
    margin-left: 68.75%;
  }

  .col-xxs-12 {
    width: 75%;
  }

  .col-xxs-push-12 {
    left: 75%;
  }

  .col-xxs-pull-12 {
    right: 75%;
  }

  .col-xxs-offset-12 {
    margin-left: 75%;
  }

  .col-xxs-13 {
    width: 81.25%;
  }

  .col-xxs-push-13 {
    left: 81.25%;
  }

  .col-xxs-pull-13 {
    right: 81.25%;
  }

  .col-xxs-offset-13 {
    margin-left: 81.25%;
  }

  .col-xxs-14 {
    width: 87.5%;
  }

  .col-xxs-push-14 {
    left: 87.5%;
  }

  .col-xxs-pull-14 {
    right: 87.5%;
  }

  .col-xxs-offset-14 {
    margin-left: 87.5%;
  }

  .col-xxs-15 {
    width: 93.75%;
  }

  .col-xxs-push-15 {
    left: 93.75%;
  }

  .col-xxs-pull-15 {
    right: 93.75%;
  }

  .col-xxs-offset-15 {
    margin-left: 93.75%;
  }

  .col-xxs-16 {
    width: 100%;
  }

  .col-xxs-push-16 {
    left: 100%;
  }

  .col-xxs-pull-16 {
    right: 100%;
  }

  .col-xxs-offset-16 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 752px) {
  .col-xs-0 {
    width: 0%;
  }

  .col-xs-push-0 {
    left: 0%;
  }

  .col-xs-pull-0 {
    right: 0%;
  }

  .col-xs-offset-0 {
    margin-left: 0%;
  }

  .col-xs-1 {
    width: 6.25%;
  }

  .col-xs-push-1 {
    left: 6.25%;
  }

  .col-xs-pull-1 {
    right: 6.25%;
  }

  .col-xs-offset-1 {
    margin-left: 6.25%;
  }

  .col-xs-2 {
    width: 12.5%;
  }

  .col-xs-push-2 {
    left: 12.5%;
  }

  .col-xs-pull-2 {
    right: 12.5%;
  }

  .col-xs-offset-2 {
    margin-left: 12.5%;
  }

  .col-xs-3 {
    width: 18.75%;
  }

  .col-xs-push-3 {
    left: 18.75%;
  }

  .col-xs-pull-3 {
    right: 18.75%;
  }

  .col-xs-offset-3 {
    margin-left: 18.75%;
  }

  .col-xs-4 {
    width: 25%;
  }

  .col-xs-push-4 {
    left: 25%;
  }

  .col-xs-pull-4 {
    right: 25%;
  }

  .col-xs-offset-4 {
    margin-left: 25%;
  }

  .col-xs-5 {
    width: 31.25%;
  }

  .col-xs-push-5 {
    left: 31.25%;
  }

  .col-xs-pull-5 {
    right: 31.25%;
  }

  .col-xs-offset-5 {
    margin-left: 31.25%;
  }

  .col-xs-6 {
    width: 37.5%;
  }

  .col-xs-push-6 {
    left: 37.5%;
  }

  .col-xs-pull-6 {
    right: 37.5%;
  }

  .col-xs-offset-6 {
    margin-left: 37.5%;
  }

  .col-xs-7 {
    width: 43.75%;
  }

  .col-xs-push-7 {
    left: 43.75%;
  }

  .col-xs-pull-7 {
    right: 43.75%;
  }

  .col-xs-offset-7 {
    margin-left: 43.75%;
  }

  .col-xs-8 {
    width: 50%;
  }

  .col-xs-push-8 {
    left: 50%;
  }

  .col-xs-pull-8 {
    right: 50%;
  }

  .col-xs-offset-8 {
    margin-left: 50%;
  }

  .col-xs-9 {
    width: 56.25%;
  }

  .col-xs-push-9 {
    left: 56.25%;
  }

  .col-xs-pull-9 {
    right: 56.25%;
  }

  .col-xs-offset-9 {
    margin-left: 56.25%;
  }

  .col-xs-10 {
    width: 62.5%;
  }

  .col-xs-push-10 {
    left: 62.5%;
  }

  .col-xs-pull-10 {
    right: 62.5%;
  }

  .col-xs-offset-10 {
    margin-left: 62.5%;
  }

  .col-xs-11 {
    width: 68.75%;
  }

  .col-xs-push-11 {
    left: 68.75%;
  }

  .col-xs-pull-11 {
    right: 68.75%;
  }

  .col-xs-offset-11 {
    margin-left: 68.75%;
  }

  .col-xs-12 {
    width: 75%;
  }

  .col-xs-push-12 {
    left: 75%;
  }

  .col-xs-pull-12 {
    right: 75%;
  }

  .col-xs-offset-12 {
    margin-left: 75%;
  }

  .col-xs-13 {
    width: 81.25%;
  }

  .col-xs-push-13 {
    left: 81.25%;
  }

  .col-xs-pull-13 {
    right: 81.25%;
  }

  .col-xs-offset-13 {
    margin-left: 81.25%;
  }

  .col-xs-14 {
    width: 87.5%;
  }

  .col-xs-push-14 {
    left: 87.5%;
  }

  .col-xs-pull-14 {
    right: 87.5%;
  }

  .col-xs-offset-14 {
    margin-left: 87.5%;
  }

  .col-xs-15 {
    width: 93.75%;
  }

  .col-xs-push-15 {
    left: 93.75%;
  }

  .col-xs-pull-15 {
    right: 93.75%;
  }

  .col-xs-offset-15 {
    margin-left: 93.75%;
  }

  .col-xs-16 {
    width: 100%;
  }

  .col-xs-push-16 {
    left: 100%;
  }

  .col-xs-pull-16 {
    right: 100%;
  }

  .col-xs-offset-16 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 972px) {
  .col-sm-0 {
    width: 0%;
  }

  .col-sm-push-0 {
    left: 0%;
  }

  .col-sm-pull-0 {
    right: 0%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-1 {
    width: 6.25%;
  }

  .col-sm-push-1 {
    left: 6.25%;
  }

  .col-sm-pull-1 {
    right: 6.25%;
  }

  .col-sm-offset-1 {
    margin-left: 6.25%;
  }

  .col-sm-2 {
    width: 12.5%;
  }

  .col-sm-push-2 {
    left: 12.5%;
  }

  .col-sm-pull-2 {
    right: 12.5%;
  }

  .col-sm-offset-2 {
    margin-left: 12.5%;
  }

  .col-sm-3 {
    width: 18.75%;
  }

  .col-sm-push-3 {
    left: 18.75%;
  }

  .col-sm-pull-3 {
    right: 18.75%;
  }

  .col-sm-offset-3 {
    margin-left: 18.75%;
  }

  .col-sm-4 {
    width: 25%;
  }

  .col-sm-push-4 {
    left: 25%;
  }

  .col-sm-pull-4 {
    right: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 25%;
  }

  .col-sm-5 {
    width: 31.25%;
  }

  .col-sm-push-5 {
    left: 31.25%;
  }

  .col-sm-pull-5 {
    right: 31.25%;
  }

  .col-sm-offset-5 {
    margin-left: 31.25%;
  }

  .col-sm-6 {
    width: 37.5%;
  }

  .col-sm-push-6 {
    left: 37.5%;
  }

  .col-sm-pull-6 {
    right: 37.5%;
  }

  .col-sm-offset-6 {
    margin-left: 37.5%;
  }

  .col-sm-7 {
    width: 43.75%;
  }

  .col-sm-push-7 {
    left: 43.75%;
  }

  .col-sm-pull-7 {
    right: 43.75%;
  }

  .col-sm-offset-7 {
    margin-left: 43.75%;
  }

  .col-sm-8 {
    width: 50%;
  }

  .col-sm-push-8 {
    left: 50%;
  }

  .col-sm-pull-8 {
    right: 50%;
  }

  .col-sm-offset-8 {
    margin-left: 50%;
  }

  .col-sm-9 {
    width: 56.25%;
  }

  .col-sm-push-9 {
    left: 56.25%;
  }

  .col-sm-pull-9 {
    right: 56.25%;
  }

  .col-sm-offset-9 {
    margin-left: 56.25%;
  }

  .col-sm-10 {
    width: 62.5%;
  }

  .col-sm-push-10 {
    left: 62.5%;
  }

  .col-sm-pull-10 {
    right: 62.5%;
  }

  .col-sm-offset-10 {
    margin-left: 62.5%;
  }

  .col-sm-11 {
    width: 68.75%;
  }

  .col-sm-push-11 {
    left: 68.75%;
  }

  .col-sm-pull-11 {
    right: 68.75%;
  }

  .col-sm-offset-11 {
    margin-left: 68.75%;
  }

  .col-sm-12 {
    width: 75%;
  }

  .col-sm-push-12 {
    left: 75%;
  }

  .col-sm-pull-12 {
    right: 75%;
  }

  .col-sm-offset-12 {
    margin-left: 75%;
  }

  .col-sm-13 {
    width: 81.25%;
  }

  .col-sm-push-13 {
    left: 81.25%;
  }

  .col-sm-pull-13 {
    right: 81.25%;
  }

  .col-sm-offset-13 {
    margin-left: 81.25%;
  }

  .col-sm-14 {
    width: 87.5%;
  }

  .col-sm-push-14 {
    left: 87.5%;
  }

  .col-sm-pull-14 {
    right: 87.5%;
  }

  .col-sm-offset-14 {
    margin-left: 87.5%;
  }

  .col-sm-15 {
    width: 93.75%;
  }

  .col-sm-push-15 {
    left: 93.75%;
  }

  .col-sm-pull-15 {
    right: 93.75%;
  }

  .col-sm-offset-15 {
    margin-left: 93.75%;
  }

  .col-sm-16 {
    width: 100%;
  }

  .col-sm-push-16 {
    left: 100%;
  }

  .col-sm-pull-16 {
    right: 100%;
  }

  .col-sm-offset-16 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1172px) {
  .col-md-0 {
    width: 0%;
  }

  .col-md-push-0 {
    left: 0%;
  }

  .col-md-pull-0 {
    right: 0%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-1 {
    width: 6.25%;
  }

  .col-md-push-1 {
    left: 6.25%;
  }

  .col-md-pull-1 {
    right: 6.25%;
  }

  .col-md-offset-1 {
    margin-left: 6.25%;
  }

  .col-md-2 {
    width: 12.5%;
  }

  .col-md-push-2 {
    left: 12.5%;
  }

  .col-md-pull-2 {
    right: 12.5%;
  }

  .col-md-offset-2 {
    margin-left: 12.5%;
  }

  .col-md-3 {
    width: 18.75%;
  }

  .col-md-push-3 {
    left: 18.75%;
  }

  .col-md-pull-3 {
    right: 18.75%;
  }

  .col-md-offset-3 {
    margin-left: 18.75%;
  }

  .col-md-4 {
    width: 25%;
  }

  .col-md-push-4 {
    left: 25%;
  }

  .col-md-pull-4 {
    right: 25%;
  }

  .col-md-offset-4 {
    margin-left: 25%;
  }

  .col-md-5 {
    width: 31.25%;
  }

  .col-md-push-5 {
    left: 31.25%;
  }

  .col-md-pull-5 {
    right: 31.25%;
  }

  .col-md-offset-5 {
    margin-left: 31.25%;
  }

  .col-md-6 {
    width: 37.5%;
  }

  .col-md-push-6 {
    left: 37.5%;
  }

  .col-md-pull-6 {
    right: 37.5%;
  }

  .col-md-offset-6 {
    margin-left: 37.5%;
  }

  .col-md-7 {
    width: 43.75%;
  }

  .col-md-push-7 {
    left: 43.75%;
  }

  .col-md-pull-7 {
    right: 43.75%;
  }

  .col-md-offset-7 {
    margin-left: 43.75%;
  }

  .col-md-8 {
    width: 50%;
  }

  .col-md-push-8 {
    left: 50%;
  }

  .col-md-pull-8 {
    right: 50%;
  }

  .col-md-offset-8 {
    margin-left: 50%;
  }

  .col-md-9 {
    width: 56.25%;
  }

  .col-md-push-9 {
    left: 56.25%;
  }

  .col-md-pull-9 {
    right: 56.25%;
  }

  .col-md-offset-9 {
    margin-left: 56.25%;
  }

  .col-md-10 {
    width: 62.5%;
  }

  .col-md-push-10 {
    left: 62.5%;
  }

  .col-md-pull-10 {
    right: 62.5%;
  }

  .col-md-offset-10 {
    margin-left: 62.5%;
  }

  .col-md-11 {
    width: 68.75%;
  }

  .col-md-push-11 {
    left: 68.75%;
  }

  .col-md-pull-11 {
    right: 68.75%;
  }

  .col-md-offset-11 {
    margin-left: 68.75%;
  }

  .col-md-12 {
    width: 75%;
  }

  .col-md-push-12 {
    left: 75%;
  }

  .col-md-pull-12 {
    right: 75%;
  }

  .col-md-offset-12 {
    margin-left: 75%;
  }

  .col-md-13 {
    width: 81.25%;
  }

  .col-md-push-13 {
    left: 81.25%;
  }

  .col-md-pull-13 {
    right: 81.25%;
  }

  .col-md-offset-13 {
    margin-left: 81.25%;
  }

  .col-md-14 {
    width: 87.5%;
  }

  .col-md-push-14 {
    left: 87.5%;
  }

  .col-md-pull-14 {
    right: 87.5%;
  }

  .col-md-offset-14 {
    margin-left: 87.5%;
  }

  .col-md-15 {
    width: 93.75%;
  }

  .col-md-push-15 {
    left: 93.75%;
  }

  .col-md-pull-15 {
    right: 93.75%;
  }

  .col-md-offset-15 {
    margin-left: 93.75%;
  }

  .col-md-16 {
    width: 100%;
  }

  .col-md-push-16 {
    left: 100%;
  }

  .col-md-pull-16 {
    right: 100%;
  }

  .col-md-offset-16 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .col-lg-0 {
    width: 0%;
  }

  .col-lg-push-0 {
    left: 0%;
  }

  .col-lg-pull-0 {
    right: 0%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-1 {
    width: 6.25%;
  }

  .col-lg-push-1 {
    left: 6.25%;
  }

  .col-lg-pull-1 {
    right: 6.25%;
  }

  .col-lg-offset-1 {
    margin-left: 6.25%;
  }

  .col-lg-2 {
    width: 12.5%;
  }

  .col-lg-push-2 {
    left: 12.5%;
  }

  .col-lg-pull-2 {
    right: 12.5%;
  }

  .col-lg-offset-2 {
    margin-left: 12.5%;
  }

  .col-lg-3 {
    width: 18.75%;
  }

  .col-lg-push-3 {
    left: 18.75%;
  }

  .col-lg-pull-3 {
    right: 18.75%;
  }

  .col-lg-offset-3 {
    margin-left: 18.75%;
  }

  .col-lg-4 {
    width: 25%;
  }

  .col-lg-push-4 {
    left: 25%;
  }

  .col-lg-pull-4 {
    right: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 25%;
  }

  .col-lg-5 {
    width: 31.25%;
  }

  .col-lg-push-5 {
    left: 31.25%;
  }

  .col-lg-pull-5 {
    right: 31.25%;
  }

  .col-lg-offset-5 {
    margin-left: 31.25%;
  }

  .col-lg-6 {
    width: 37.5%;
  }

  .col-lg-push-6 {
    left: 37.5%;
  }

  .col-lg-pull-6 {
    right: 37.5%;
  }

  .col-lg-offset-6 {
    margin-left: 37.5%;
  }

  .col-lg-7 {
    width: 43.75%;
  }

  .col-lg-push-7 {
    left: 43.75%;
  }

  .col-lg-pull-7 {
    right: 43.75%;
  }

  .col-lg-offset-7 {
    margin-left: 43.75%;
  }

  .col-lg-8 {
    width: 50%;
  }

  .col-lg-push-8 {
    left: 50%;
  }

  .col-lg-pull-8 {
    right: 50%;
  }

  .col-lg-offset-8 {
    margin-left: 50%;
  }

  .col-lg-9 {
    width: 56.25%;
  }

  .col-lg-push-9 {
    left: 56.25%;
  }

  .col-lg-pull-9 {
    right: 56.25%;
  }

  .col-lg-offset-9 {
    margin-left: 56.25%;
  }

  .col-lg-10 {
    width: 62.5%;
  }

  .col-lg-push-10 {
    left: 62.5%;
  }

  .col-lg-pull-10 {
    right: 62.5%;
  }

  .col-lg-offset-10 {
    margin-left: 62.5%;
  }

  .col-lg-11 {
    width: 68.75%;
  }

  .col-lg-push-11 {
    left: 68.75%;
  }

  .col-lg-pull-11 {
    right: 68.75%;
  }

  .col-lg-offset-11 {
    margin-left: 68.75%;
  }

  .col-lg-12 {
    width: 75%;
  }

  .col-lg-push-12 {
    left: 75%;
  }

  .col-lg-pull-12 {
    right: 75%;
  }

  .col-lg-offset-12 {
    margin-left: 75%;
  }

  .col-lg-13 {
    width: 81.25%;
  }

  .col-lg-push-13 {
    left: 81.25%;
  }

  .col-lg-pull-13 {
    right: 81.25%;
  }

  .col-lg-offset-13 {
    margin-left: 81.25%;
  }

  .col-lg-14 {
    width: 87.5%;
  }

  .col-lg-push-14 {
    left: 87.5%;
  }

  .col-lg-pull-14 {
    right: 87.5%;
  }

  .col-lg-offset-14 {
    margin-left: 87.5%;
  }

  .col-lg-15 {
    width: 93.75%;
  }

  .col-lg-push-15 {
    left: 93.75%;
  }

  .col-lg-pull-15 {
    right: 93.75%;
  }

  .col-lg-offset-15 {
    margin-left: 93.75%;
  }

  .col-lg-16 {
    width: 100%;
  }

  .col-lg-push-16 {
    left: 100%;
  }

  .col-lg-pull-16 {
    right: 100%;
  }

  .col-lg-offset-16 {
    margin-left: 100%;
  }
}
.header {
  z-index: 100;
  position: relative;
  background: white;
  box-shadow: 0 10px 130px rgba(79, 227, 194, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
@media (max-width: 60.74em) {
  .header {
    height: 70px;
  }
}
@media (max-width: 60.74em) and (max-width: 46.99em) {
  .header {
    height: 70px;
  }
}

.header__nav {
  background: #FFFFFF;
}
@media only screen and (max-width: 780px) {
  .header__banner-responsive {
    display: block !important;
  }
}
@media only screen and (max-width: 780px) {
  .header__responsive-logo-footer {
    margin-left: 30%;
  }
}
@media only screen and (max-width: 780px) {
  .header__responsive-frame-video {
    width: 300px;
    height: 300px;
  }
}

.footer {
  /* Rectangle 2: */
  background-image: linear-gradient(-180deg, #000f24 0%, #ff203c 100%);
  margin-top: 240px;
  margin-top: 15rem;
}
@media (max-width: 46.99em) {
  .footer div[class^="col-"] {
    margin: 12px 0px;
    margin: 0.75rem 0;
  }
}
.footer .section-title {
  margin-bottom: 48px;
  margin-bottom: 3rem;
  margin-top: 0px;
  margin-top: 0;
}
.footer__pre {
  border-bottom: 3px solid white;
  color: white;
  padding: 24px 0px;
  padding: 1.5rem 0;
}
@media (max-width: 46.99em) {
  .footer__pre {
    padding: 0px;
    padding: 0;
    border: 0;
  }
}
.footer__content {
  padding: 24px 0px 12px 0px;
  padding: 1.5rem 0 0.75rem 0;
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  color: white;
}
@media (max-width: 46.99em) {
  .footer__content {
    padding: 0px;
    padding: 0;
  }
}
.footer__follow-link {
  color: white;
  font-size: 30px;
  font-size: 1.875rem;
  display: inline-block;
  margin: 0px 6px;
  margin: 0 0.375rem;
  transition: all 0.5s ease-in-out;
}
.footer__follow-link:hover.facebook {
  color: #306199;
}
.footer__follow-link:hover.twitter {
  color: #26c4f1;
}
.footer__follow-link:hover.instagram {
  color: #517fa4;
}
.footer__follow-link:hover.youtube {
  color: #bb0000;
}
.footer__follow-link:hover.vimeo {
  color: #4bf;
}
.footer__link {
  color: white;
  border-bottom: 1px solid #50E3C2;
  font-weight: 400;
}
.footer__separator {
  width: 100%;
  background: white;
  height: 3px;
  margin: 24px 0px;
  margin: 1.5rem 0;
}
@media (max-width: 46.99em) {
  .footer__separator {
    margin: 0px;
    margin: 0;
  }
}
.footer__separator--small {
  height: 1px;
}
@media (max-width: 89.99em) {
  .footer__partner {
    width: 80px;
  }
}
@media (max-width: 73.24em) {
  .footer__partner {
    width: 80px;
  }
}
@media (max-width: 60.74em) {
  .footer__partner {
    width: 120px;
  }
}
@media (max-width: 46.99em) {
  .footer__partner {
    width: 80px;
    height: auto;
  }
}
.footer__partner-separator {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  margin: 0px 24px;
  margin: 0 1.5rem;
  position: relative;
  top: -30px;
}
@media (max-width: 46.99em) {
  .footer__partner-separator {
    color: white;
  }
}
@media (max-width: 46.99em) {
  .footer .section-title {
    color: white;
  }
}

.nav-actions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.nav-actions__item {
  display: inline-block;
  color: white;
  text-transform: lowercase;
  margin: 0px 12px;
  margin: 0 0.75rem;
}
.nav-actions__item:nth-child(1), .nav-actions__item:nth-child(2) {
  color: white;
}
.nav-actions__item:nth-child(2):after {
  content: '|';
  display: inline-block;
  margin: 0px 0px 0px 24px;
  margin: 0 0 0 1.5rem;
  color: white;
}
.nav-actions__item-link {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
  font-size: 1rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-family: "Oswald", sans-serif;
}
.sidebar .nav-actions {
  padding: 12px 24px;
  padding: 0.75rem 1.5rem;
  text-align: left;
}
.sidebar .nav-actions__item {
  display: block;
  margin: 0px 0px 12px 0px;
  margin: 0 0 0.75rem 0;
}
.sidebar .nav-actions__item:nth-child(2):after {
  display: none;
}
.sidebar .nav-actions__item-link {
  color: black;
  font-size: 20px;
  font-size: 1.25rem;
}
.sidebar .nav-actions__item-link:hover {
  color: black;
}
.sidebar .nav-actions:before {
  display: block;
  content: "";
  background: #979797;
  width: 240px;
  height: 1px;
  margin: 0px 0px 36px 0px;
  margin: 0 0 2.25rem 0;
}
.sidebar .nav-actions:after {
  display: block;
  content: "";
  background: black;
  width: 240px;
  height: 6px;
  margin: 36px 0px 12px 0px;
  margin: 2.25rem 0 0.75rem 0;
}
.nav-actions__transform-word {
  text-transform: lowercase;
}
.nav-actions__separator_responsive_menu {
  width: auto;
  height: 1px;
  background: black;
  margin-bottom: 5%;
}
@media screen and (max-width: 780px) {
  .nav-actions__transform-word {
    text-transform: uppercase;
  }
}

.nav-header {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
}
@media (max-width: 60.74em) {
  .nav-header {
    margin: 0px;
    margin: 0;
  }
}
.nav-header .logo-translated {
  margin-left: -75px;
  height: 194px;
  /*@include mq($until: lg) {
      margin-left : auto;
      margin-right : auto;
      width : 50px;
      height: 5Opx;
  }*/
}
@media (max-width: 89.99em) {
  .nav-header .logo-translated {
    margin-left: -107px;
  }
}
.nav-header__top-menu-user {
  color: white !important;
  font-family: "Oswald",sans-serif !important;
  font-size: 1rem !important;
  margin-right: 40px !important;
}
.nav-header__items-menu-user-dropdown {
  padding: 0px !important;
  margin: 0px !important;
  list-style: none !important;
  text-align: left !important;
}
.nav-header__image-logo {
  margin-top: -1.2em;
}
.nav-header__switch-display {
  display: block;
}
.nav-header__space-responsive {
  padding-bottom: 0% !important;
}
.nav-header__responsive-image {
  display: none !important;
  margin-top: 0px;
}
.nav-header__non-responsive-image {
  display: block !important;
}
.nav-header__image-logo-responsive {
  width: 50% !important;
  height: 50% !important;
  margin-top: 13% !important;
  margin-left: 21% !important;
  margin-bottom: 20% !important;
}
@media screen and (max-width: 780px) {
  .nav-header__image-logo {
    width: 50% !important;
    height: 50% !important;
    margin-top: 10% !important;
    margin-left: auto !important;
  }
  .nav-header__switch-display {
    display: none;
  }
  .nav-header__space-responsive {
    padding-bottom: 8% !important;
  }
  .nav-header__responsive-image {
    display: block !important;
    margin-top: -27px !important;
    width: 60% !important;
    margin-left: 30px !important;
  }
  .nav-header__non-responsive-image {
    display: none !important;
  }
}
.nav-header__main-submenu-items {
  background: #000f24 !important;
  text-align: center !important;
  left: 0px !important;
  padding: 0px !important;
}
.nav-header__item-subheader-menu {
  margin-left: 0px !important;
  margin-right: 0px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: auto !important;
}
.nav-header__list_items-subheader-menu {
  padding-left: 0px !important;
  list-style: none !important;
}
.nav-header__single-item-subheader-menu {
  display: block !important;
  color: white !important;
  padding-bottom: 8px !important;
  background: #000f24 !important;
}
.nav-header__single-item-subheader-menu-highlight {
  display: block !important;
  color: #000f24 !important;
  padding-bottom: 8px !important;
  background: white !important;
}
.nav-header__single-item-subheader-menu-link {
  display: block !important;
  color: white !important;
  margin-top: 5px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  background: #000f24 !important;
}
.nav-header__single-item-subheader-menu-link-highlight {
  display: block !important;
  color: #000f24 !important;
  margin-top: 5px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  background: white !important;
}
.nav-header__dropdown-menu {
  font-weight: bold;
  align: right;
  font-size: 13px;
  float: right;
  margin-right: 5px;
  position: absolute !important;
  right: 0px !important;
}
.nav-header .translated {
  width: 196px;
}
.nav-header__item-separator {
  width: 100%;
  height: 1px;
  background: white;
}
.nav-header__item-separator-user-menu {
  width: 100%;
  height: 1px;
  background: white;
}
.nav-header__content-header {
  background: #000f24;
  color: white;
  width: 100%;
  text-align: center;
  position: relative;
}
.nav-header__item-user-menu {
  background: #000f24 none repeat scroll 0% 0%;
  text-align: center;
  width: 200px;
  display: none;
  position: absolute;
}
.nav-header__link-user-menu {
  display: block;
  color: white;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Oswald",sans-serif;
  font-size: 15px;
}
.nav-header__item-user {
  display: inline-block;
  color: black;
  vertical-align: top;
  position: relative;
  display: block;
}
.nav-header__item-user:before {
  content: "";
  display: block;
  height: 45px;
  width: 1px;
  background: black;
  position: absolute;
  top: 0;
}
@media (max-width: 89.99em) {
  .nav-header__item-user:before {
    height: 35px;
  }
}
.nav-header__item-user.hover:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 1px;
  right: 0;
  bottom: -1px;
  z-index: 3;
  background: #F8F8F8;
  border-top: 1px solid #E3E3E3;
  border-left: 1px solid #E3E3E3;
  border-right: 1px solid #E3E3E3;
  border-bottom: 1px solid #F8F8F8;
}
.nav-header__item-responsive {
  top: 0 !important;
}
@media screen and (max-width: 780px) {
  .nav-header__item-responsive {
    margin-top: 20px !important;
    margin-left: 10px !important;
    margin-bottom: -28px !important;
  }
}
@-moz-document url-prefix() {
  @media screen and (max-width: 780px) {
    .nav-header__item-responsive {
      margin-top: 10px !important;
      margin-left: 10px !important;
      margin-bottom: -18px !important;
    }
  }
}
.nav-header__hide-first-line {
  background: white !important;
}
.nav-header__hide-first-line:before {
  background: white !important;
}
.nav-header__hide-first-line:after {
  background: white !important;
}
.nav-header__item {
  display: inline-block;
  color: black;
  vertical-align: top;
  position: relative;
}
.nav-header__item:before {
  content: "";
  display: block;
  height: 45px;
  width: 1px;
  background: black;
  position: absolute;
  top: 0;
  margin-right: 12px;
  margin-right: 0.75rem;
}
@media (max-width: 89.99em) {
  .nav-header__item:before {
    height: 35px;
  }
}
.nav-header__item--no-separator:before {
  display: none;
}
.nav-header__item--separator {
  padding-left: 36px;
  padding-left: 2.25rem;
}
.nav-header__item--separator:before {
  margin: 0px 24px;
  margin: 0 1.5rem;
}
@media (max-width: 73.24em) {
  .nav-header__item--separator {
    padding-left: 24px;
    padding-left: 1.5rem;
  }
  .nav-header__item--separator:before {
    margin: 0px 12px;
    margin: 0 0.75rem;
  }
}
.nav-header__item:first-child {
  display: none;
  z-index: 3;
}
.nav-header__item:first-child:before {
  display: none;
}
.nav-header__item:nth-child(2):before {
  display: none;
}
.nav-header__item:last-child:after {
  display: none;
}
.nav-header__item--image {
  vertical-align: top;
  margin: 0px 0px 0px 12px;
  margin: 0 0 0 0.75rem;
  padding-right: 12px;
  padding-right: 0.75rem;
  /*@include mq($until: md) {
      @include margin(0 0 0 .25);
      @include padding-right(.25);
  }*/
}
@media (max-width: 60.74em) {
  .nav-header__item--image img {
    height: 70px;
    width: auto;
    display: block;
  }
}
.nav-header__item--image-big {
  vertical-align: top;
  margin: 0px 24px 0px 60px;
  margin: 0 1.5rem 0 3.75rem;
  padding-right: 12px;
  padding-right: 0.75rem;
  height: 45px;
  float: left !important;
}
@media (max-width: 89.99em) {
  .nav-header__item--image-big {
    height: 35px;
  }
  .nav-header__item--image-big img {
    width: 100%;
    height: 100%;
    margin-top: -1em;
  }
}
@media (max-width: 73.24em) {
  .nav-header__item--image-big {
    height: 35px;
  }
  .nav-header__item--image-big img {
    width: 80%;
    height: 80%;
    margin-top: 0;
  }
}
@media (max-width: 60.74em) {
  .nav-header__item--image-big {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 2;
    padding: 0px;
    padding: 0;
    margin: 0px;
    margin: 0;
    margin-left: -80px;
    text-align: center;
  }
  .nav-header__item--image-big img {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 46.99em) {
  .nav-header__item--image-big img {
    display: block;
    margin: 0 auto;
  }
}
@media screen and (max-width: 780px) {
  .nav-header__item--image-big {
    float: none !important;
  }
}
.nav-header__item--special {
  z-index: 3;
  vertical-align: top;
  margin: 0px 0px 0px 12px;
  margin: 0 0 0 0.75rem;
  padding-right: 12px;
  padding-right: 0.75rem;
  /*@include mq($until: md) {
      @include margin(0 0 0 .25);
      @include padding-right(.25);
  }*/
}
.nav-header__item--centered {
  z-index: 3;
  line-height: 35px;
}
@media (max-width: 89.99em) {
  .nav-header__item--centered {
    line-height: 32px;
  }
}
@media (max-width: 60.74em) {
  .nav-header__item--centered {
    line-height: 70px;
    height: 60px;
  }
}
@media (max-width: 46.99em) {
  .nav-header__item--centered {
    line-height: 70px;
    height: 60px;
  }
}
.nav-header__item--right {
  float: right;
}
.nav-header__item.hover:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 1px;
  right: 0;
  bottom: -1px;
  z-index: 3;
  background: #F8F8F8;
  border-top: 1px solid #E3E3E3;
  border-left: 1px solid black;
  border-right: 1px solid #E3E3E3;
  border-bottom: 1px solid #F8F8F8;
}
@media (max-width: 60.74em) {
  .nav-header__item {
    display: none;
  }
  .nav-header__item:first-child, .nav-header__item:nth-child(2), .nav-header__item:last-child {
    display: inline-block;
  }
  .nav-header__item:first-child {
    float: left;
  }
  .nav-header__item:last-child {
    float: right;
  }
  .nav-header__item:after {
    display: none;
  }
}
.nav-header__item-link {
  text-transform: uppercase;
  color: #000F24;
  text-decoration: none;
  position: relative;
  z-index: 4;
  vertical-align: top;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-family: "Oswald", sans-serif;
  padding-right: 24px;
  padding-right: 1.5rem;
}
@media (max-width: 89.99em) {
  .nav-header__item-link {
    font-size: 16px;
    font-size: 1rem;
    padding-right: 18px;
    padding-right: 1.125rem;
  }
}
@media (max-width: 73.24em) {
  .nav-header__item-link {
    font-size: 14px;
    font-size: 0.875rem;
    padding-right: 12px;
    padding-right: 0.75rem;
  }
}
.nav-header__item-link:before {
  display: inline-block;
  content: '';
  vertical-align: top;
  margin-right: 6px;
  margin-right: 0.375rem;
}
.nav-header__item-link--no-dot {
  padding-right: 6px;
  padding-right: 0.375rem;
  font-weight: 300;
}
.nav-header__item-link--no-dot:before {
  content: '';
}
.nav-header__item-link--no-dot.active {
  font-weight: 400;
}
.nav-header__item-link--no-dot.active:after {
  display: none !important;
}
.nav-header__item-link.active:after {
  position: absolute;
  height: 80%;
  display: block;
  top: 10%;
  left: 0px;
  content: "";
  background: #50E3C2;
  z-index: -1;
  right: 1px;
}
.nav-header__submenu {
  position: absolute;
  z-index: 2;
  background: white;
  display: none;
  background-color: #F8F8F8;
  border: 1px solid #E3E3E3;
  text-align: center;
  box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.12);
}
.nav-header__submenu p {
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}
@media (max-width: 89.99em) {
  .nav-header__submenu p {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.nav-header__submenu p:after {
  content: "";
  display: block;
  width: 200px;
  height: 1px;
  background: #979797;
}
.nav-header__item-sub {
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: none;
  position: relative;
  /*&:after {
      position: absolute;
      height: 8px;
      margin-top: -4px;
      width: 100%;
      display: block;
      top: 50%;
      left: 0;
      content: "";
      background: $color-main;
      z-index: -1;
      @include transform(scaleX(0));
      @include transition(all 0.25s ease-in-out);
      transform-origin:left top;
  }*/
}
@media (max-width: 89.99em) {
  .nav-header__item-sub {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.nav-header__item-sub:hover {
            /*>a {
                color: black;
            }

            &:after {
                @include transform(scaleX(1));
                position: absolute;
                height: 8px;
                margin-top: -4px;
                width: 100%;
                display: block;
                top: 50%;
                left: 0;
                content: "";
                background: $color-main;
                z-index: -1;
            }*/
}
.nav-header__item-sub:hover > a {
  border-bottom: 1px solid #50E3C2;
}
.nav-header__item-sub--first {
  /*&:after {
      display: none;
  }*/
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.nav-header__item-sub--first:hover > a {
  border-bottom: 0;
}
@media (max-width: 89.99em) {
  .nav-header__item-sub--first {
    font-size: 16px;
    font-size: 1rem;
  }
}
.home-mobile-nav .nav-header__item {
  display: none;
}
.home-mobile-nav .nav-header__item:nth-child(3), .home-mobile-nav .nav-header__item:nth-child(4), .home-mobile-nav .nav-header__item:nth-child(5), .home-mobile-nav .nav-header__item:nth-child(6) {
  display: inline-block;
}
.home-mobile-nav .nav-header__item-link {
  padding: 0 5px;
}
@media (max-width: 60.74em) {
  .home-mobile-nav .nav-header__item-link {
    font-size: 18px;
  }
}
@media (max-width: 46.99em) {
  .home-mobile-nav .nav-header__item-link {
    font-size: 14px;
  }
}
.home-mobile-subnav .nav-header__item {
  display: none;
  margin: 0;
  padding: 0;
}
.home-mobile-subnav .nav-header__item:nth-child(7), .home-mobile-subnav .nav-header__item:nth-child(8) {
  display: inline-block;
}
.home-mobile-subnav .nav-header__item--image {
  width: 45%;
}
.home-mobile-subnav .nav-header__item--image img {
  display: inline-block;
  height: auto;
  width: 80%;
}
.sidebar .nav-header {
  padding: 12px 24px 0px 24px;
  padding: 0.75rem 1.5rem 0 1.5rem;
  text-align: left;
}
.sidebar .nav-header__item {
  display: block;
  text-align: left;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.sidebar .nav-header__item:first-child, .sidebar .nav-header__item:nth-child(1), .sidebar .nav-header__item:last-child {
  display: none;
}
.sidebar .nav-header__item.hover:before {
  display: none;
}
.sidebar .nav-header__item--image-big {
  position: static;
  height: auto;
  margin-top: 10px;
  margin-bottom: 72px;
  margin-bottom: 4.5rem;
}
.sidebar .nav-header__item--special {
  display: inline-block;
  width: 50%;
  padding: 0;
  margin: 0;
}
.sidebar .nav-header__item--special a {
  color: black;
}
.sidebar .nav-header__item--image {
  display: inline-block;
  width: 50%;
  padding: 0;
  margin: 0;
}
.sidebar .nav-header__item--image img {
  height: 80px;
  width: auto;
  display: block;
}
.sidebar .nav-header__item--right {
  float: none;
}
.sidebar .nav-header__item--separator {
  margin-top: 72px;
  margin-top: 4.5rem;
  padding: 0px;
  padding: 0;
}
.sidebar .nav-header__item:before {
  display: none;
}
.sidebar .nav-header__item-link {
  color: #000F24;
  font-size: 20px;
  font-size: 1.25rem;
}
.sidebar .nav-header__item-link:hover {
  color: #000F24;
}
.sidebar .nav-header__item-link--no-dot {
  display: inline-block;
  width: auto;
}
.sidebar .nav-header__submenu {
  display: none !important;
}
.nav-header__search-magnify-style {
  background: white;
  float: right;
  padding-right: 2%;
  padding-bottom: 8px;
  position: relative;
}
@media screen and (max-width: 780px) {
  .nav-header__search-magnify-style {
    margin-top: 0% !important;
  }
}
@media screen and (max-width: 780px) and (-webkit-min-device-pixel-ratio: 0) {
  .nav-header__search-magnify-style {
    margin-top: 3% !important;
  }
}

@-moz-document url-prefix() {
  @media screen and (max-width: 780px) {
    .nav-header__search-magnify-style {
      margin-top: 25px !important;
      margin-bottom: -40px !important;
    }
  }
}
.nav-footer {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.nav-footer--horizontal .nav-footer__item {
  display: inline-block;
  margin-right: 48px;
  margin-right: 3rem;
}
.nav-footer--horizontal .nav-footer__item-link {
  font-size: 14px;
  font-size: 0.875rem;
}
.nav-footer__item-link {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  color: white;
}

.nav-tree {
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 2;
  position: absolute;
  width: 100%;
  opacity: 1;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: translateX(0);
}
@media (max-width: 60.74em) {
  .nav-tree {
    position: static;
  }
}
.nav-tree-wrapper .nav-tree {
  position: static;
}
.nav-tree .nav-tree {
  position: relative;
}
.nav-tree .nav-tree .nav-tree__item:first-child {
  margin-top: 2px;
}
.nav-tree.faded {
  opacity: 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: translateX(-20px);
}
.nav-tree.is_stuck {
  background: white;
}
.nav-tree--level-0 {
  margin-bottom: 48px;
  margin-bottom: 3rem;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.15);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
}
@media (max-width: 60.74em) {
  .nav-tree--level-0 {
    border-top: 1px solid white;
  }
}
.nav-tree__item {
  margin-top: 2px;
}
.nav-tree__item:first-child {
  margin-top: 0;
}
.nav-tree__separator {
  margin: 48px 0px;
  margin: 3rem 0;
}
.nav-tree__link {
  display: block;
  color: white;
  background: #343432;
  padding: 4px 20px;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Oswald", sans-serif;
}
.nav-tree__link.active {
  background: #50E3C2;
  color: #343432;
  padding: 8px 20px;
  font-size: 14px;
  font-size: 0.875rem;
}
.nav-tree__link--filter {
  background: #efefef;
  color: #1D1D1B;
}
.nav-tree__link--sub {
  padding-left: 48px;
  padding-left: 3rem;
  background: #a8f1e1;
  color: #343432;
  transition: all 0.25s ease-in-out;
}
.nav-tree__link--sub.active, .nav-tree__link--sub:hover {
  background: #7cead1;
  padding: 4px 20px;
  padding-left: 48px;
  padding-left: 3rem;
  color: #343432;
  font-weight: 400;
  font-size: 12px;
  font-size: 0.75rem;
  color: black;
}
.nav-tree__link i {
  float: right;
  position: relative;
  top: 5px;
}

.sidebar {
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  /* Rectangle 2: */
  background-image: linear-gradient(to top, rgba(79, 227, 194, 0) 0%, rgba(80, 227, 194, 0.3) 100%);
}
.sidebar__menu {
  padding: 0px 24px;
  padding: 0 1.5rem;
  text-align: left;
  list-style-type: none;
  margin: 0;
}
.sidebar__menu-item {
  font-size: 25px;
  font-size: 1.5625rem;
  font-family: "Oswald", sans-serif;
  line-height: 48px;
  line-height: 3rem;
  font-weight: 400;
}
.sidebar__menu-item a {
  color: black;
}
.sidebar__menu-item a:hover {
  color: black;
}
.sidebar .col-xs-3 {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  display: block;
  margin: 0px 0px 12px 0px;
  margin: 0 0 0.75rem 0;
}
.sidebar .col-xs-3:first-of-type {
  margin-top: 10px;
}

.audio-playlist {
  margin-top: 0;
  padding: 0;
  background: #343432;
  list-style-type: none;
  color: #000F24;
}
.audio-playlist__item {
  padding: 6px 24px;
  padding: 0.375rem 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Oswald", sans-serif;
  transition: all 0.25s ease-in-out;
  font-style: italic;
  color: white;
}
.audio-playlist__item small {
  font-style: normal;
  display: inline-block;
  margin-left: 5px;
}
.audio-playlist__item.playing {
  background: #50E3C2;
  color: #343432;
}
.audio-playlist__item.playing > a {
  color: #343432;
}
.audio-playlist__item > a {
  transition: all 0.25s ease-in-out;
  color: white;
}

.video-playlist {
  margin-top: 0;
  padding: 0;
  background: #343432;
  list-style-type: none;
}
.video-playlist__item {
  padding: 6px 24px;
  padding: 0.375rem 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Oswald", sans-serif;
  transition: all 0.25s ease-in-out;
  color: white;
  font-style: italic;
}
.video-playlist__item small {
  font-style: normal;
  display: inline-block;
  margin-left: 5px;
}
.video-playlist__item.playing {
  background: #50E3C2;
  color: #343432;
}
.video-playlist__item.playing > a {
  color: #343432;
}
.video-playlist__item > a {
  transition: all 0.25s ease-in-out;
  color: white;
}

.article-box {
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  border: 1px solid rgba(80, 227, 194, 0.35);
  padding: 24px;
  padding: 1.5rem;
  height: 550px;
}
@media (max-width: 46.99em) {
  .article-box {
    height: auto;
  }
}
@media (max-width: 73.24em) {
  .article-box {
    padding: 12px;
    padding: 0.75rem;
  }
}
.slider-home .article-box {
  margin: 0px;
  margin: 0;
  max-width: none;
  height: auto;
}
.article-box:hover {
  border: 1px solid #50e3c2;
  background: #50E3C2;
}
.article-box:hover .article-box__image-container {
  background: black;
}
.article-box:hover .article-box__image img {
  filter: grayscale(0);
}
.article-box:hover .article-box__title {
  color: #000F24;
}
.article-box:hover .article-box__tags {
  color: #000F24;
}
.article-box:hover .article-box__subtitle {
  color: #000F24;
}
.article-box:hover .article-box__desc {
  color: #000F24;
}
.article-box:hover .article-box__card-subtag-event {
  background: #000f24;
  transition: background 0.5s ease-in-out;
}
.article-box:hover .article-box__card-tag-event {
  background: #50e3c2;
  border-right: 1px solid #000f24;
  border-bottom: 1px solid #000f24;
  transition: border-right 0.5s ease-in-out;
  transition: border-bottom 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.article-box__tag-container {
  padding-left: 45%;
  margin-bottom: 20px;
  margin-top: -60px;
}
.article-box__tag-event {
  background: clear;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  border-right: 1px solid #50e3c2;
  border-bottom: 1px solid #50e3c2;
  padding-left: 18px;
  text-align: left;
  position: relative;
}
.article-box__tag-event-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  color: white;
}
.article-box__subtag-event {
  background: #50e3c2;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  text-align: center;
  position: relative;
  margin-left: 10%;
}
.article-box__subtag-event-content {
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
}
.article-box__header {
  position: relative;
}
.article-box__image-container {
  background: #000F24;
  transition: all 0.5s ease-in-out;
}
.article-box__image {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.article-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.article-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.article-box__placeholder {
  background: #50E3C2;
}
.article-box__placeholder.recherche, .article-box__placeholder.research {
  background-color: white;
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.article-box__placeholder.creation {
  background-color: white;
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.article-box__placeholder.transmission {
  background-color: white;
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.article-box__placeholder.innovation, .article-box__placeholder.innovations {
  background-color: white;
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}
.article-box__tags {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 400;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.article-box__btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  margin-right: 12px;
  margin-right: 0.75rem;
}
.article-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 46.99em) {
  .article-box__content {
    height: auto;
  }
}
.slider-home .article-box__content {
  padding: 0px;
  padding: 0;
}
.article-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.article-box__tag {
  color: #50E3C2;
}
.article-box__subtitle {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  margin-top: 6px;
  margin-top: 0.375rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out;
}
.article-box__subtitle span {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 6px;
  margin-top: 0.375rem;
}
.slider-home .article-box__subtitle {
  margin-top: 12px;
  margin-top: 0.75rem;
}
.article-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}
.article-box--small .article-box__content {
  height: auto;
}
.article-box--small .article-box__content:after {
  display: none;
}
.article-box--small .article-box__btn {
  display: none;
}
.slider-home .article-box {
  display: inline-block;
  max-width: 450px;
  background: transparent;
}
.slider-home .article-box__content {
  background: transparent;
  margin-top: 1px;
}
.article-box--person {
  margin-top: 48px;
  margin-top: 3rem;
  position: relative;
  border: 0;
  padding: 0px;
  padding: 0;
  height: auto;
}
.article-box--person:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #50E3C2;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}
.article-box--person .article-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 6px 0px 6px 0px;
  margin: 0.375rem 0 0.375rem 0;
  font-weight: 900;
}
.article-box--person .article-box__image {
  padding-bottom: 117.09602%;
  position: relative;
}
.article-box--person .article-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.article-box--person .article-box__desc {
  line-height: 24px;
  line-height: 1.5rem;
}
.article-box--person .article-box__content {
            /*&:after {

                @include mq($until: xs) {
                    display: none;
                }

                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100px;
                background-color: rgba($color-background, 0);
                @include filter-gradient(rgba($color-background, 1), rgba($color-background, 1), vertical);
                @include background-image(linear-gradient(top, rgba($color-background, 0) 0%, rgba($color-background, 1) 50%));
            }*/
}
@media (max-width: 46.99em) {
  .article-box--person .article-box__content {
    height: auto;
  }
}
.article-box--person:hover {
  border: 0;
  background-color: #000F24;
}
.article-box--person:hover .article-box__title {
  color: #50E3C2;
}
.article-box--person:hover .article-box__desc {
  color: white;
}
.article-box__card-tag-container {
  position: absolute;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 2;
}
.article-box__card-tag-event {
  background: #000f24;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  border-right: 1px solid #50e3c2;
  border-bottom: 1px solid #50e3c2;
  padding-left: 18px;
  text-align: left;
  position: relative;
}
.article-box__card-tag-event-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  color: white;
}
.article-box__card-subtag-event {
  background: #50e3c2;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  text-align: center;
  position: relative;
  margin-left: 35%;
}
.article-box__card-subtag-event-content {
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
}
.article-box__apply_button {
  position: absolute;
  top: -0.25em;
  left: -0.01em;
  width: 6em;
  height: 4em;
  color: #FFFFFF;
  border-color: #FF203C;
  background-color: #FF203C;
  border-radius: 0.5em;
  border-style: solid;
  border-width: 1em;
  padding: 0.3em;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.25em;
  font-family: "Oswald",sans-serif !important;
  text-align: center;
  z-index: 1;
}

.page-box {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (max-width: 60.74em) {
  .page-box {
    height: auto;
    overflow: hidden;
  }
}
.page-box:first-of-type {
  margin-top: 72px;
  margin-top: 4.5rem;
}
.page-box__image {
  padding-bottom: 44.44444%;
  position: relative;
}
.page-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 46.99em) {
  .page-box__image {
    padding-bottom: 177.77778%;
    position: relative;
  }
  .page-box__image > :first-child {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .page-box__image > img:first-child {
    width: 310%;
    left: -50%;
    max-width: none;
  }
}
.page-box__image:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.25s ease-in-out;
}
.page-box__placeholder {
  background: #50E3C2;
}
.page-box__placeholder.pattern-bg--squares {
  background-color: white;
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.page-box__placeholder.pattern-bg--circles {
  background-color: white;
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.page-box__placeholder.pattern-bg--stripes {
  background-color: white;
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.page-box__placeholder.pattern-bg--triangles {
  background-color: white;
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}
.page-box__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  padding: 24px;
  padding: 1.5rem;
}
.page-box__title {
  color: #50E3C2;
  font-weight: 900;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
}
.page-box__subtitle {
  color: white;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

.brief-box {
  position: relative;
  padding: 24px;
  padding: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  text-align: center;
  background: #5F5F5E;
}
.brief-box__head {
  text-transform: uppercase;
  display: inline-block;
  background: #868686;
  color: #000000;
  padding: 0px 24px;
  padding: 0 1.5rem;
  font-size: 16px;
  font-size: 1rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.brief-box__title {
  display: block;
  margin: 0 auto;
  color: #FFFFFF;
  margin-top: 96px;
  margin-top: 6rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", serif;
  font-weight: 900;
}
@media (max-width: 89.99em) {
  .brief-box__title {
    margin-top: 48px;
    margin-top: 3rem;
  }
}
.brief-box__desc {
  display: block;
  margin: 0 auto;
  color: #FFFFFF;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}
.brief-box__button {
  margin-bottom: 96px;
  margin-bottom: 6rem;
  margin-right: 0;
}
@media (max-width: 89.99em) {
  .brief-box__button {
    margin-bottom: 48px;
    margin-bottom: 3rem;
  }
}
.brief-box--black {
  background: #1D1D1B;
}
.brief-box--black .brief-box__title {
  color: white;
}
.brief-box--black .brief-box__desc {
  color: white;
}
.brief-box--yellow {
  background: #50E3C2;
}
.brief-box--yellow .brief-box__title {
  color: #1D1D1B;
}
.brief-box--yellow .brief-box__desc {
  color: #1D1D1B;
}

.person-list-box {
  position: relative;
  margin-top: 48px;
  margin-top: 3rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.person-list-box__image {
  margin: 0 auto;
  width: 135px;
  height: 135px;
  border-radius: 135px;
}
@media (max-width: 46.99em) {
  .person-list-box__image {
    margin: 0;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.person-list-box__image img {
  border-radius: 135px;
}
.person-list-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 48px 0px;
  margin: 1.5rem 0 3rem 0;
  font-weight: 300;
}

.event-line-box {
  position: relative;
  display: block;
  border: 1px solid rgba(80, 227, 194, 0.35);
  background: #000F24;
  transition: all 0.5s ease-in-out;
  margin-bottom: 120px;
  margin-bottom: 7.5rem;
  /*
      &:hover {
  
          border: 1px solid rgba($color-main, 1);
          background: black;
  
          .event-line-box__image {
  
              -webkit-filter: grayscale(0);
              filter: grayscale(0);
  
          }
  
          .event-line-box__category {
  
              background: $color-main;
  
          }
  
          .event-line-box__date {
  
              color: $color-main;
  
          }
      }
  */
}
@media (max-width: 60.74em) {
  .event-line-box {
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.lightSlider .event-line-box {
  margin-bottom: 0 !important;
}
.event-line-box:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Rectangle 3: */
  background-image: linear-gradient(to left, rgba(0, 15, 36, 0) 0%, rgba(0, 15, 36, 0.9) 99%);
  z-index: 1;
}
@media (max-width: 60.74em) {
  .event-line-box:after {
    display: none;
  }
}
.event-line-box__tag-event {
  background: clear;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  border-right: 1px solid rgba(80, 227, 194, 0.35);
  border-bottom: 1px solid rgba(80, 227, 194, 0.35);
  padding-left: 5px;
  text-align: left;
  position: relative;
}
.event-line-box__tag-event:hover {
  background: none;
  border-right: 1px solid #50e3c2;
  border-bottom: 1px solid #50e3c2;
}
.event-line-box__tag-event-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  color: white;
}
.event-line-box__subtag-event {
  background: #50e3c2;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  text-align: center;
  position: relative;
  margin-left: 5%;
}
.event-line-box__subtag-event-content {
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
}
.event-line-box__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: left;
  z-index: 2;
  /*@include mq($until: lg) {
      height: 312px;
  }
  @include mq($until: md) {
      height: 361px;
  }*/
}
@media (max-width: 60.74em) {
  .event-line-box__content {
    position: static;
  }
}
.event-line-box__month {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 900;
  visibility: hidden;
  height: 0;
}
.event-line-box__image {
  padding-bottom: 38.83495%;
  position: relative;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.event-line-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.event-line-box__button {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
@media (max-width: 60.74em) {
  .event-line-box__button {
    top: 70px;
    width: auto;
  }
}
.event-line-box__tags {
  margin-bottom: 5px;
}
.event-line-box__category {
  position: absolute;
  top: 50%;
  left: 0;
  background: white;
  color: #000F24;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  padding: 2.4px 12px 2.4px 12px;
  padding: 0.15rem 0.75rem 0.15rem 0.75rem;
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out;
  width: 10rem;
}
@media (max-width: 60.74em) {
  .event-line-box__category {
    top: 70px;
    width: auto;
    padding: 2.4px 12px 2.4px 48px;
    padding: 0.15rem 0.75rem 0.15rem 3rem;
  }
}
.event-line-box__body {
  position: absolute;
  top: 50%;
  left: 12rem;
  transform: translateY(-50%);
}
@media (max-width: 60.74em) {
  .event-line-box__body {
    position: static;
    transform: translateY(0);
    padding: 24px;
    padding: 1.5rem;
  }
}
.event-line-box__title {
  font-size: 36px;
  line-height: 36px;
  line-height: 2.25rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #50E3C2;
  margin: 0 !important;
}
.event-line-box__title a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  color: #50E3C2;
  border: 0;
}
.event-line-box__title a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: none;
}
.event-line-box__location {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  color: #50E3C2;
}
.event-line-box__date {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  color: white;
  transition: all 0.5s ease-in-out;
  margin-top: 24px;
  margin-top: 1.5rem;
}
.event-line-box__date span {
  display: block;
  font-size: 16px;
  font-size: 1rem;
}
.event-line-box__desc {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  color: #50E3C2;
}
.event-line-box__title-center {
  font-size: 36px;
  line-height: 36px;
  line-height: 2.25rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  color: white;
  margin: 0 !important;
}
.event-line-box__title-center a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  color: #50E3C2;
  border: 0;
}
.event-line-box__title-center a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: none;
}
.event-line-box__body-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 60.74em) {
  .event-line-box__body-center {
    position: static;
    transform: translateY(0);
    padding: 24px;
    padding: 1.5rem;
  }
}
.event-line-box__top-space {
  margin-top: 120px;
  margin-top: 7.5rem;
}
.event-line-box__top-space-left {
  margin-top: 1.5rem;
}
.event-line-box__text-color-date {
  font-family: "Oswald", sans-serif;
  color: #50E3C2;
  font-size: 18px;
}
.event-line-box__center-buttons {
  background: none;
  width: 100%;
  display: table;
  text-align: center;
  text-transform: uppercase;
  margin-top: 150px;
  font-size: 15px;
  font-family: "Oswald", sans-serif;
  color: white;
}
.event-line-box__event-button {
  background: none;
  border: none;
  color: #19494c;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-weight: normal;
  margin-right: 30px;
  margin-left: 30px;
  padding: 0;
  width: 180px;
  margin-bottom: 0px;
}
.event-line-box__event-button:hover {
  background: none;
  color: #50E3C2;
  font-size: 22px;
}
.event-line-box__event-button.active {
  cursor: normal;
  background: none;
  color: #50E3C2;
  font-size: 22px;
}
.event-line-box__event-button-selected {
  background: none;
  color: #50E3C2;
  font-size: 22px;
}
.event-line-box__event-button-selected:hover {
  background: none;
  color: #50E3C2;
  font-size: 22px;
}
.event-line-box__event-button-unselected {
  background: none;
  color: white;
  font-size: 22px;
}
.event-line-box__event-button-unselected:hover {
  background: none;
  color: #50E3C2;
  font-size: 22px;
}
.event-line-box__underline-text-green {
  text-align: center;
  display: inline-block;
  line-height: 1.5em;
  text-decoration: none;
  border-bottom: 2px solid #50E3C2;
}
.event-line-box__underline-text-unselected {
  text-align: center;
  display: inline-block;
  line-height: 1.5em;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.event-line-box__block--white {
  background-color: #FFFFFF;
  color: #1D1D1B;
  padding-top: 48px;
  padding-top: 3rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.event-line-box__text-event-paragraph {
  font-family: "Merriweather", serif;
}
.event-line-box__hide-container {
  display: none;
}
.event-line-box__show-container {
  display: block;
}
.event-line-box__margin-paragraph-bottom {
  margin-bottom: 5%;
}
.event-line-box__center-text {
  text-align: center;
  margin-bottom: 20px;
}
.event-line-box__margins {
  margin-right: 10%;
  margin-left: 10%;
}
.event-line-box__block--white {
  background-color: white;
  color: #000F24;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  position: relative;
}
.event-line-box__text-events-list {
  font-family: "Oswald", sans-serif;
}
.event-line-box__bold-text {
  font-weight: bold;
}
.event-line-box__dark-blue {
  text-color: #525a67;
}
.event-line-box__section-title {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: white;
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 96px;
  margin-top: 0px;
}
.event-line-box__white-color {
  text-color: white;
}
.event-line-box__turquoise-color {
  text-color: #50e3c2;
}
.event-line-box__small-margin {
  margin-bottom: 15px;
}
.event-line-box__title-list {
  font-size: 36px;
  line-height: 36px;
  line-height: 2.25rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  margin: 0 !important;
}
.event-line-box__date-list {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  color: #50e3c2;
  transition: all 0.5s ease-in-out;
  margin-top: 24px;
  margin-top: 1.5rem;
}
.event-line-box__date-list span {
  display: block;
  font-size: 16px;
  font-size: 1rem;
}
.event-line-box__location-list {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  color: #50E3C2;
}

.job-line-box:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.25);
  margin: 48px 0px 24px 0px;
  margin: 3rem 0 1.5rem 0;
}
@media (max-width: 60.74em) {
  .job-line-box:after {
    margin: 24px 0px 24px 0px;
    margin: 1.5rem 0 1.5rem 0;
  }
}
.job-line-box--candidacy .job-line-box__title {
  margin-top: 0px;
  margin-top: 0;
}
.job-line-box--candidacy .job-line-box__image {
  border-radius: 150px;
}
.job-line-box__title {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: 12px;
  margin-top: 0.75rem;
}
.job-line-box__desc {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.product-box {
  position: relative;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.product-box__image {
  padding-bottom: 31.25%;
  position: relative;
}
.product-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.product-box__title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 48px;
  line-height: 3rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
}
.product-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
}
.product-box__content {
  padding: 12px;
  padding: 0.75rem;
  height: 150px;
}
.product-box:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #979797;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}

.residency-box {
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  border: 1px solid rgba(80, 227, 194, 0.35);
  height: 402px;
  width: 402px;
  /*
  @include mq($until: md) {
      @include padding(.5);
  }
  */
}
@media (max-width: 46.99em) {
  .residency-box {
    height: auto;
  }
}
.slider-home .residency-box {
  margin: 0px;
  margin: 0;
  max-width: none;
  height: auto;
}
.residency-box:hover {
  border: 1px solid #50e3c2;
  background: #50E3C2;
  /*
  .residency-box__subtitle {
      color: $color-background;
  }
  */
}
.residency-box:hover .residency-box__image-container {
  background: black;
}
.residency-box:hover .residency-box__image img {
  filter: grayscale(0);
}
.residency-box:hover .residency-box__title {
  color: #000F24;
  opacity: 0;
  vertical-align: top;
  text-alegment: left;
}
.residency-box:hover .residency-box__tags {
  color: #000F24;
}
.residency-box:hover .residency-box__desc {
  color: #000F24;
}
.residency-box:hover .residency-box__label_overlay {
  opacity: 1;
}
.residency-box:hover .residency-box__background-margin {
  opacity: 0;
}
.residency-box__background-image {
  width: 100;
}
.residency-box__background {
  /*
      background: linear-gradient(
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)
      );
      */
  background-size: cover;
  width: 400px;
  height: 400px;
}
.residency-box__background-margin {
  margin: 20px 20px 20px 20px;
  background-size: 90% 90%;
  background-repeat: no-repeat;
}
.residency-box__background:hover {
  opacity: 0;
}
.residency-box__background-margin:hover {
  opacity: 0;
}
.residency-box__background:hover .residency-box__label_overlay {
  opacity: 1;
}
.residency-box__label_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.residency-box__label_overlay_title {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-weight: 700;
  color: black;
  font-weight: 700;
  transition: color 0.5s ease-in-out;
  text-transform: uppercase;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 0px;
}
.residency-box__label_overlay_body {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  margin-top: 6px;
  margin-top: 0.375rem;
  color: black;
  transition: color 0.5s ease-in-out;
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
}
.residency-box__header {
  position: relative;
}
.residency-box__image-container {
  background: #000F24;
  transition: all 0.5s ease-in-out;
}
.residency-box__image {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.residency-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.residency-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.residency-box__placeholder {
  background: #50E3C2;
}
.residency-box__placeholder.recherche, .residency-box__placeholder.research {
  background-color: white;
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.residency-box__placeholder.creation {
  background-color: white;
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.residency-box__placeholder.transmission {
  background-color: white;
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.residency-box__placeholder.innovation, .residency-box__placeholder.innovations {
  background-color: white;
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}
.residency-box__tags {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 400;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.residency-box__btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  margin-right: 12px;
  margin-right: 0.75rem;
}
.residency-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 46.99em) {
  .residency-box__content {
    height: auto;
  }
}
.slider-home .residency-box__content {
  padding: 0px;
  padding: 0;
}
.residency-box__title {
  font-size: 42px;
  font-size: 2.625rem;
  /*TODO: Bigger */
  line-height: 54px;
  line-height: 3.375rem;
  /*TODO: Bigger*/
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.residency-box__tag {
  color: #50E3C2;
}
.residency-box__subtitle {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  margin-top: 6px;
  margin-top: 0.375rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out;
}
.residency-box__subtitle span {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 6px;
  margin-top: 0.375rem;
}
.slider-home .residency-box__subtitle {
  margin-top: 12px;
  margin-top: 0.75rem;
}
.residency-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}
.residency-box--small .residency-box__content {
  height: auto;
}
.residency-box--small .residency-box__content:after {
  display: none;
}
.residency-box--small .residency-box__btn {
  display: none;
}
.slider-home .residency-box {
  display: inline-block;
  max-width: 450px;
  background: transparent;
}
.slider-home .residency-box__content {
  background: transparent;
  margin-top: 1px;
}
.residency-box--person {
  margin-top: 48px;
  margin-top: 3rem;
  position: relative;
  border: 0;
  padding: 0px;
  padding: 0;
  height: auto;
}
.residency-box--person:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #50E3C2;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}
.residency-box--person .residency-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  font-weight: 900;
}
.residency-box--person .residency-box__image {
  padding-bottom: 117.09602%;
  position: relative;
}
.residency-box--person .residency-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.residency-box--person .residency-box__desc {
  line-height: 24px;
  line-height: 1.5rem;
}
.residency-box--person .residency-box__content {
            /*&:after {

                @include mq($until: xs) {
                    display: none;
                }

                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100px;
                background-color: rgba($color-background, 0);
                @include filter-gradient(rgba($color-background, 1), rgba($color-background, 1), vertical);
                @include background-image(linear-gradient(top, rgba($color-background, 0) 0%, rgba($color-background, 1) 50%));
            }*/
}
@media (max-width: 46.99em) {
  .residency-box--person .residency-box__content {
    height: auto;
  }
}
.residency-box--person:hover {
  border: 0;
  background-color: #000F24;
  /*
  .residency-box__title {
      color: $color-main;
  }
  .residency-box__desc {
      color: white;
  }
  */
}

.search-box {
  position: relative;
  overflow: hidden;
  *zoom: 1;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.search-box:hover .search-box__title {
  color: #50E3C2;
}
.search-box__title {
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  transition: color 0.25s ease-in-out;
}
.search-box__title i {
  display: inline-block;
  margin-left: 12px;
  margin-left: 0.75rem;
  font-size: 0.75em;
  position: relative;
  top: -5px;
}
.search-box__subtitle {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  margin-top: 6px;
  margin-top: 0.375rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out;
}
.search-box__subtitle span {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 6px;
  margin-top: 0.375rem;
}
.search-box__tags {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 400;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.search-box__content {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  transition: color 0.5s ease-in-out;
}
.search-box__image-wrapper {
  float: left;
  width: 20%;
  display: block;
}
.search-box__image-wrapper + .search-box__right {
  width: 80%;
  float: left;
  padding-left: 24px;
  padding-left: 1.5rem;
}
@media (max-width: 46.99em) {
  .search-box__image-wrapper {
    display: none;
  }
  .search-box__image-wrapper + .search-box__right {
    width: 100%;
    float: none;
    padding-left: 0px;
    padding-left: 0;
  }
}
.search-box__image {
  padding-bottom: 60.41667%;
  position: relative;
}
.search-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.search-box__image--playlist {
  padding-bottom: 60.41667%;
  position: relative;
}
.search-box__image--playlist > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.search-box__image--video:after {
  font-family: FontAwesome;
  display: block;
  content: "\f04b";
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  line-height: 50px;
  text-align: center;
  text-indent: 3px;
}
.search-box__image--audio:after {
  font-family: FontAwesome;
  display: block;
  content: "\f028";
  text-indent: 0px;
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  line-height: 50px;
  text-align: center;
  text-indent: 3px;
}

.media-box {
  position: relative;
  display: block;
  text-align: left;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  border: 1px solid rgba(80, 227, 194, 0.35);
  padding: 24px;
  padding: 1.5rem;
  height: 550px;
}
@media (max-width: 46.99em) {
  .media-box {
    height: auto;
  }
}
@media (max-width: 73.24em) {
  .media-box {
    padding: 12px;
    padding: 0.75rem;
  }
}
.media-box--audio .media-box__placeholder {
  background: rgba(80, 227, 194, 0.35);
}
.media-box:hover {
  border: 1px solid #50e3c2;
  background: #50E3C2;
}
.media-box:hover .media-box__image-container {
  background: black;
}
.media-box:hover .media-box__image img {
  filter: grayscale(0);
}
.media-box:hover .media-box__title {
  color: #000F24;
}
.media-box:hover .media-box__type {
  color: #000F24;
}
.media-box:hover .media-box__subtitle {
  color: #000F24;
}
.media-box:hover .media-box__desc {
  color: #000F24;
}
.media-box__image-container {
  background: #000F24;
  display: block;
  transition: all 0.5s ease-in-out;
}
.media-box__placeholder {
  background: #50E3C2;
}
.media-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
  display: block;
}
@media (max-width: 46.99em) {
  .media-box__content {
    height: auto;
  }
}
.slider-home .media-box__content {
  padding: 0px;
  padding: 0;
}
.media-box__image {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.media-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.media-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.media-box__image:after {
  content: "\f04b";
  text-indent: 0px;
  color: white;
  font-family: FontAwesome;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.media-box__image--audio:after {
  content: "\f028";
}
.media-box__type {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 400;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.media-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.media-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}

.button-residency {
  background: #FFFFFF;
  color: #1D1D1B;
  border: 1px solid #50E3C2;
  border-radius: 7px;
  outline: 0;
  box-sizing: border-box;
  min-width: 136px;
  margin: 0 .75rem 10px 0;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all .25s ease-in-out;
  text-decoration: inherit;
  text-align: center;
  list-style: none;
}
.button-residency:hover {
  background: #000F24;
  color: #FFFFFF;
}
.button-residency-selected {
  background: #000F24;
  color: #FFFFFF;
}
.button-residency-selected:hover {
  background: #FFFFFF;
  color: #000F24;
}
.button-residency-selected::after {
  color: #50E3C2;
  content: "✗";
  margin-left: 5px;
  float: right;
}
.button-residency-unselected {
  background: #FFFFFF;
  color: #1D1D1B;
}
.button-residency-unselected:hover {
  background: #1D1D1B;
  color: #FFFFFF;
}
.button-residency-unselected::after {
  content: "✔";
  margin-left: 5px;
  float: right;
}

.residency-detail__header {
  width: 100%;
}
.residency-detail__header::after {
  clear: both;
}
.residency-detail__header-previous {
  width: 15%;
  float: left;
}
.residency-detail__header-previous a {
  margin-top: 150px;
  margin-left: 50px;
  display: inline-block;
  height: 100px;
  width: 100px;
  border: 3px solid #50E3C2;
  border-radius: 3px;
  border-top: 0;
  border-right: 0;
  transform: rotateZ(45deg);
}
.residency-detail__header-next {
  width: 15%;
  float: right;
}
.residency-detail__header-next a {
  margin-top: 150px;
  margin-left: -o-calc(100% - 150px);
  margin-left: calc(100% - 150px);
  display: inline-block;
  height: 100px;
  width: 100px;
  border: 3px solid #50E3C2;
  border-radius: 3px;
  border-top: 0;
  border-left: 0;
  transform: rotateZ(-45deg);
}
.residency-detail__header-image {
  width: 70%;
  margin: 0 auto;
  display: block;
  height: 400px;
  position: relative;
  background-image: url("");
  background-repeat: no-repeat;
  background-position: center;
}
.residency-detail__header-image span {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 125px;
  color: #50E3C2;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}
.residency-detail__header-text {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 5%;
  margin-top: 2%;
  color: #FFFFFF;
}
.residency-detail__margin-global {
  margin-top: 0%;
  margin-right: 15%;
  margin-bottom: 0%;
  margin-left: 15%;
}
.residency-detail__background-main {
  background-color: #000F24;
}
.residency-detail__button {
  background: none;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  font-weight: normal;
  margin-right: 30px;
  margin-left: 30px;
  padding: 0;
  width: 180px;
  margin-bottom: 0px;
}
.residency-detail__button:hover {
  background: clear !important;
}
.residency-detail__button.active {
  cursor: normal;
  color: #50E3C2;
}
.residency-detail__button-selected {
  background: clear !important;
  color: #50E3C2;
}
.residency-detail__button-selected:hover {
  background: clear !important;
}
.residency-detail__button-unselected {
  color: white;
}
.residency-detail__block--white {
  background-color: #FFFFFF;
  color: #1D1D1B;
  padding-top: 48px;
  padding-top: 3rem;
  margin: 48px 0px 0px 0px;
  margin: 3rem 0 0 0;
  position: relative;
  overflow: hidden;
}
.residency-detail__margin-row {
  margin-bottom: 10px;
}
.residency-detail__margin-white {
  margin: 3px 0px 0px 0px;
}
@media only screen and (max-width: 780px) {
  .residency-detail__margin-white {
    margin-right: -22% !important;
    margin-left: -22% !important;
  }
}
.residency-detail__center-buttons {
  width: auto;
  margin: 0 auto;
  display: table;
  text-align: center;
  text-transform: uppercase;
}
.residency-detail__hide-container {
  display: none;
}
.residency-detail__show-container {
  display: block;
}
.residency-detail__margin-block {
  margin-right: 10%;
  margin-left: 10%;
}
.residency-detail__image-left {
  float: left;
  margin: 0 20px 20px 0;
}
.residency-detail__container {
  margin-left: 320px;
  margin-right: 200px;
  position: relative;
}
.residency-detail__title {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
}
.residency-detail__text {
  text-decoration: none;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
}
.residency-detail__underline-text {
  text-decoration: underline;
}
.residency-detail__underline-text-green {
  font-family: "Oswald", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  line-height: 1.5em;
  text-transform: uppercase;
  text-align: center;
  color: #1D1D1B;
  text-decoration: none;
  border-bottom: 2px solid #50E3C2;
  display: table;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.5em;
}
.residency-detail__green-top-line {
  border-top: 2px solid #50E3C2;
}
@media only screen and (max-width: 780px) {
  .residency-detail__green-top-line {
    margin-right: -22% !important;
    margin-left: -22% !important;
  }
}
.residency-detail__video-style {
  height: 390px;
  width: 640px;
  display: table;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}
.residency-detail__right-image {
  float: right;
  margin-bottom: 20px;
}
.residency-detail__width-text-second-tab {
  width: 70%;
}
.residency-detail__text-detail {
  overflow: hidden;
  white-space: pre-wrap;
  padding: 0px 25px 25px 0px;
  font-size: 0.9 rem;
}
.residency-detail__text-detail p a {
  color: #1D1D1B !important;
}
.residency-detail__button-project {
  background: #50E3C2;
  color: #1D1D1B;
  border-radius: 7px;
  outline: 0;
  box-sizing: border-box;
  min-width: 136px;
  margin: 0 .75rem 10px 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all .25s ease-in-out;
  text-decoration: inherit;
  text-align: center;
  list-style: none;
  font-size: 18px;
  border: 1px solid #1D1D1B;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 650;
}
.residency-detail__disable-overflow {
  overflow: hidden;
}
.residency-detail__margin-bottom-third-tab {
  margin-bottom: 5%;
  margin-left: 10%;
}
.residency-detail__social-links {
  margin: 25px 0px 25px 0px;
}
.residency-detail__social-link {
  margin: 0px 5px 0px 0px;
}
.residency-detail__map {
  position: relative;
  width: 300px;
  height: 300px;
  float: right;
  border-style: solid;
  border-color: #50E3C2;
  border-width: 1px;
  margin: 10px 0px 50px 0px;
}
.residency-detail__map::after {
  clear: both;
}
.residency-detail__details {
  overflow: hidden;
}
.residency-detail__content-detail {
  margin-right: 10%;
  margin-left: 10%;
}
.residency-detail__single-margin-top {
  margin-top: 5%;
}
.residency-detail__artist-section-margin {
  margin-bottom: 2%;
  margin-left: 10%;
}
.residency-detail__custom-image-margin {
  margin-left: 10%;
  margin-right: 10%;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
}
.residency-detail__item-left-align {
  text-align: left;
}
.residency-detail__title-technology-section {
  font-weight: bold;
  font-size: 30px;
  margin-top: 1%;
  margin-bottom: 1%;
}
.residency-detail__green-style-color {
  color: #50e3c2;
}
.residency-detail__single-margin-top {
  margin-bottom: 1%;
}
.residency-detail__margin-custom-residency {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%;
}
.residency-detail__producer-margin {
  margin-top: 3%;
  margin-bottom: 5%;
}
.residency-detail__frame-producer-square {
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
}
.residency-detail__tile-producer-style {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: black;
}
.residency-detail__text-style-produces-top {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
.residency-detail__margin-main-top {
  margin-top: -50px;
}
.residency-detail__banner-style-top {
  background: #000f24;
  width: 100%;
  height: auto;
  display: block;
  padding-right: 20%;
  padding-left: 20%;
  padding-top: 2%;
  padding-bottom: 5%;
  margin-bottom: 3%;
}
.residency-detail__first-row-style-banner {
  text-align: center;
  color: #50e3c2;
  font-family: 'Oswald',sans-serif;
  font-size: 17px;
  margin-bottom: 5%;
}
.residency-detail__second-row-style-banner {
  text-align: center;
  color: white;
  font-family: 'Oswald',sans-serif;
  font-size: 30px;
  line-height: 1.2em;
}
.residency-detail__tags-style-banner {
  text-align: center;
  padding-left: 15% !important;
  padding-right: 15% !important;
}
.residency-detail__container-image-banner-outcome {
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: #000f24;
}
.residency-detail__image-banner-outcome {
  position: absolute;
  top: 40%;
  width: 100%;
  font-family: 'Oswald',sans-serif;
  font-size: 5em;
  color: #50e3c2;
}
.residency-detail__button-residency-style {
  outline: 0;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.5rem;
  font-family: Oswald,sans-serif;
  vertical-align: middle;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.event-line-detail-box {
  position: relative;
  display: block;
  border: none;
  background-color: transparent;
}
.event-line-detail-box__tag-container {
  padding-left: 45%;
  margin-bottom: 20px;
  margin-top: -80px;
}
.event-line-detail-box__tag-event {
  background: clear;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  border-right: 1px solid #50e3c2;
  border-bottom: 1px solid #50e3c2;
  padding-left: 18px;
  text-align: left;
  position: relative;
}
.event-line-detail-box__tag-event-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  color: white;
}
.event-line-detail-box__subtag-event {
  background: #50e3c2;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  width: 110px;
  height: 30px;
  top: 50%;
  text-align: center;
  position: relative;
  margin-left: 10%;
}
.event-line-detail-box__subtag-event-content {
  bottom: 0;
  margin-bottom: 0px;
  font-family: "Oswald",sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
}
.event-line-detail-box__back-link {
  display: block;
  font-size: 18px;
  border: none;
  position: absolute;
  top: 76%;
  font-family: "Oswald", sans-serif;
  text-align: left;
  color: white;
  margin-left: 10px;
  z-index: 3;
}
.event-line-detail-box__container-back-link {
  width: 100%;
  text-align: left;
}
.event-line-detail-box__arrow-align {
  display: inline-block;
  font-size: 30px;
}
.event-line-detail-box__map-event {
  position: relative;
  width: 245px;
  height: 245px;
  float: right;
  border-style: solid;
  border-color: #50E3C2;
  border-width: 1px;
  margin: 10px 0px 50px 0px;
}
@media only screen and (max-width: 780px) {
  .event-line-detail-box__map-event {
    margin-right: 14% !important;
  }
}
@media only screen and (max-width: 780px) {
  .event-line-detail-box__responsive-arrow {
    margin-top: 14% !important;
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 780px) {
  .event-line-detail-box__responsive-center-text {
    text-align: center !important;
  }
}

.event-line-box-hover {
  position: relative;
  display: block;
  border: 1px solid rgba(80, 227, 194, 0.35);
  background: #000F24;
  transition: all 0.5s ease-in-out;
  margin-bottom: 120px;
  margin-bottom: 7.5rem;
}
@media (max-width: 60.74em) {
  .event-line-box-hover {
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.lightSlider .event-line-box-hover {
  margin-bottom: 0 !important;
}
.event-line-box-hover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Rectangle 3: */
  background-image: linear-gradient(to left, rgba(0, 15, 36, 0) 0%, rgba(0, 15, 36, 0.9) 99%);
  z-index: 1;
}
@media (max-width: 60.74em) {
  .event-line-box-hover:after {
    display: none;
  }
}
.event-line-box-hover:hover {
  border: 1px solid #50e3c2;
  background: black;
}
.event-line-box-hover:hover .event-line-box__image {
  filter: grayscale(0);
}
.event-line-box-hover:hover .event-line-box__category {
  background: #50E3C2;
}
.event-line-box-hover:hover .event-line-box__date {
  color: #50E3C2;
}
.event-line-box-hover:hover .event-line-box__tag-event {
  background: none;
  border-right: 1px solid #50e3c2;
  border-bottom: 1px solid #50e3c2;
}

.jury-box {
  background: none;
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  height: 550px;
  padding: 24px;
  padding: 1.5rem;
}
.jury-box__header {
  position: relative;
}
.jury-box__header:after {
  content: "";
  display: block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.jury-box__margin-right {
  margin-right: 30px;
}
.jury-box__person {
  margin-top: 48px;
  margin-top: 3rem;
  position: relative;
  border: 0;
  padding: 0px;
  padding: 0;
  height: auto;
}
.jury-box__image {
  margin: 0;
  padding: 0;
  width: 90%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.jury-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.jury-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  mix-blend-mode: screen;
}
.jury-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
}
.jury-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", "sans";
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: white;
  transition: color 0.5s ease-in-out;
}
.jury-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", "sans";
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}
.jury-box__top-margin {
  margin-top: 20px;
}

.producers-box {
  background: none;
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  height: 550px;
  padding: 24px;
  padding: 1.5rem;
}
.producers-box__header {
  position: relative;
}
.producers-box__header:after {
  content: "";
  display: block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8));
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.producers-box__single-header {
  content: "";
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.producers-box__margin-right {
  margin-right: 30px;
}
.producers-box__person {
  margin-top: 48px;
  margin-top: 3rem;
  position: relative;
  border: 0;
  padding: 0px;
  padding: 0;
  height: auto;
}
.producers-box__image {
  margin: 0;
  padding: 0;
  width: 90%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.producers-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.producers-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  mix-blend-mode: screen;
}
.producers-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
}
.producers-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Merriweather", "sans";
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: white;
  transition: color 0.5s ease-in-out;
}
.producers-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", "sans";
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}
.producers-box__top-margin {
  margin-top: 20px;
}
.producers-box__image-credit {
  font-size: 13px;
  color: white;
  background: clear;
}

.slider-box {
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease-in-out;
  border: 0;
  padding: 0px;
  padding: 0;
  height: 550px;
}
@media (max-width: 46.99em) {
  .slider-box {
    height: auto;
  }
}
@media (max-width: 73.24em) {
  .slider-box {
    padding: 12px;
    padding: 0.75rem;
  }
}
.slider-home .slider-box {
  margin: 0px;
  margin: 0;
  max-width: none;
  height: auto;
}
.slider-box:hover {
  border: 1px solid #50e3c2;
  background: #50E3C2;
}
.slider-box:hover .slider-box__image-container {
  background: black;
}
.slider-box:hover .slider-box__image img {
  filter: grayscale(0);
}
.slider-box:hover .slider-box__title {
  color: #000F24;
}
.slider-box:hover .slider-box__tags {
  color: #000F24;
}
.slider-box:hover .slider-box__subtitle {
  color: #000F24;
}
.slider-box:hover .slider-box__desc {
  color: #000F24;
}
.slider-box__header {
  position: relative;
}
.slider-box__image-container {
  background: #000F24;
  transition: all 0.5s ease-in-out;
}
.slider-box__image {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.slider-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.slider-box__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.slider-box__placeholder {
  background: #50E3C2;
}
.slider-box__placeholder.recherche, .slider-box__placeholder.research {
  background-color: white;
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.slider-box__placeholder.creation {
  background-color: white;
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.slider-box__placeholder.transmission {
  background-color: white;
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.slider-box__placeholder.innovation, .slider-box__placeholder.innovations {
  background-color: white;
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}
.slider-box__tags {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
  font-weight: 400;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.slider-box__btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  margin-right: 12px;
  margin-right: 0.75rem;
}
.slider-box__content {
  padding: 6px 6px 6px 6px;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 46.99em) {
  .slider-box__content {
    height: auto;
  }
}
.slider-home .slider-box__content {
  padding: 0px;
  padding: 0;
}
.slider-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: #50E3C2;
  transition: color 0.5s ease-in-out;
}
.slider-box__title--accent {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 12px 0px 0px 0px;
  margin: 0.75rem 0 0 0;
  font-weight: 700;
  color: #FF203C;
  transition: color 0.5s ease-in-out;
}
.slider-box__tag {
  color: #50E3C2;
}
.slider-box__subtitle {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  margin-top: 6px;
  margin-top: 0.375rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out;
}
.slider-box__subtitle span {
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 6px;
  margin-top: 0.375rem;
}
.slider-home .slider-box__subtitle {
  margin-top: 12px;
  margin-top: 0.75rem;
}
.slider-box__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Merriweather", serif;
  margin: 24px 0px 6px 0px;
  margin: 1.5rem 0 0.375rem 0;
  font-weight: 300;
  color: white;
  transition: color 0.5s ease-in-out;
}
.slider-box--small .slider-box__content {
  height: auto;
}
.slider-box--small .slider-box__content:after {
  display: none;
}
.slider-box--small .slider-box__btn {
  display: none;
}
.slider-home .slider-box {
  display: inline-block;
  max-width: 450px;
  background: transparent;
}
.slider-home .slider-box__content {
  background: transparent;
  margin-top: 1px;
}
.slider-box--person {
  margin-top: 48px;
  margin-top: 3rem;
  position: relative;
  border: 0;
  padding: 0px;
  padding: 0;
  height: auto;
}
.slider-box--person:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #50E3C2;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
}
.slider-box--person .slider-box__title {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: "Oswald", sans-serif;
  margin: 6px 0px 6px 0px;
  margin: 0.375rem 0 0.375rem 0;
  font-weight: 900;
}
.slider-box--person .slider-box__image {
  padding-bottom: 117.09602%;
  position: relative;
}
.slider-box--person .slider-box__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.slider-box--person .slider-box__desc {
  line-height: 24px;
  line-height: 1.5rem;
}
.slider-box--person .slider-box__content {
            /*&:after {

                @include mq($until: xs) {
                    display: none;
                }

                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100px;
                background-color: rgba($color-background, 0);
                @include filter-gradient(rgba($color-background, 1), rgba($color-background, 1), vertical);
                @include background-image(linear-gradient(top, rgba($color-background, 0) 0%, rgba($color-background, 1) 50%));
            }*/
}
@media (max-width: 46.99em) {
  .slider-box--person .slider-box__content {
    height: auto;
  }
}
.slider-box--person:hover {
  border: 0;
  background-color: #000F24;
}
.slider-box--person:hover .slider-box__title {
  color: #50E3C2;
}
.slider-box--person:hover .slider-box__desc {
  color: white;
}

.slider-page {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 60.75em) {
  .slider-page.flexbox {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.slider-page__slide {
  text-align: center;
  visibility: hidden;
}
.lightSlider .slider-page__slide {
  visibility: visible;
}
.flexbox .slider-page__slide {
  visibility: visible;
  padding: 0 5px;
}
@media (min-width: 60.75em) {
  .flexbox .slider-page__slide {
    -ms-flex-order: 0;
    order: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
        align-self: auto;
  }
}
.slider-page__wrapper {
  position: relative;
}
@media (max-width: 60.74em) {
  .slider-page__img {
    width: 100%;
  }
}
.slider-page__caption {
  font-weight: 300;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
  line-height: 1.125rem;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
}
.slider-page__video {
  margin-bottom: 5px;
  padding-bottom: 57.45856%;
  position: relative;
  width: 905px;
}
.slider-page__video > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 46.99em) {
  .slider-page__video {
    width: 100%;
  }
}

.slider-home {
  position: relative;
  margin-bottom: 96px;
  margin-bottom: 6rem;
}
@media (max-width: 60.74em) {
  .slider-home {
    display: none;
  }
}
.slider-home ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.slider-home__slide {
  width: 100%;
  position: relative;
  height: 760px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.slider-home__wrapper {
  position: absolute;
  bottom: 75px;
  left: 75px;
  right: 0;
  padding: 24px;
  padding: 1.5rem;
  display: table;
  width: 25%;
  background: #000F24;
  transition: background 0.5s ease-in-out;
  border: 1px solid #50E3C2;
}
.slider-home__wrapper:hover {
  background: #50E3C2;
}
.slider-home__wrapper:hover .slider-home__image-container {
  background: black;
}
.slider-home__wrapper:hover .slider-home__image {
  filter: grayscale(0);
}
.slider-home__wrapper:hover .article-box__title {
  color: #000F24;
}
.slider-home__wrapper:hover .article-box__tags {
  color: #000F24;
}
.slider-home__wrapper:hover .article-box__subtitle {
  color: #000F24;
}
.slider-home__wrapper:hover .article-box__desc {
  color: #000F24;
}
.slider-home__wrapper--accent {
  position: absolute;
  bottom: 75px;
  left: 75px;
  right: 0;
  padding: 24px;
  padding: 1.5rem;
  display: table;
  width: 25%;
  background: #000F24;
  transition: background 0.5s ease-in-out;
  border: 1px solid #FF203C;
}
.slider-home__wrapper--accent:hover {
  background: #FF203C;
}
.slider-home__wrapper--accent:hover .slider-home__image-container {
  background: black;
}
.slider-home__wrapper--accent:hover .slider-home__image {
  filter: grayscale(0);
}
.slider-home__wrapper--accent:hover .article-box__title--accent {
  color: #000F24;
}
.slider-home__wrapper--accent:hover .article-box__tags {
  color: #000F24;
}
.slider-home__wrapper--accent:hover .article-box__subtitle {
  color: #000F24;
}
.slider-home__wrapper--accent:hover .article-box__desc {
  color: #000F24;
}
.slider-home__image-container {
  background: #000F24;
  transition: all 0.5s ease-in-out;
}
.slider-home__image {
  width: 100%;
  display: block;
  transition: all 0.5s ease-in-out;
  filter: grayscale(1);
  mix-blend-mode: screen;
  transition: all 0.5s ease-in-out;
}
.slider-home__content {
  display: table-cell;
  vertical-align: middle;
  height: 373px;
}
@media (max-width: 89.99em) {
  .slider-home__content {
    height: 296px;
  }
}
@media (max-width: 73.24em) {
  .slider-home__content {
    height: 245px;
  }
}
@media (max-width: 60.74em) {
  .slider-home__content {
    height: auto;
    display: block;
    margin-top: 24px;
    margin-top: 1.5rem;
  }
}
.slider-home__credits {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  padding: 6px 12px;
  padding: 0.375rem 0.75rem;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}
.slider-home .slider-box {
  padding: 0px;
  padding: 0;
  border: 0;
}
.slider-home__pager {
  position: absolute;
  bottom: 33px;
  left: 0px;
  right: 0;
}
.slider-home__pager ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.slider-home__pager ul li {
  display: inline-block;
  margin-right: 10px;
}
.slider-home__pager ul li a {
  display: block;
  width: 14px;
  height: 14px;
  background: black;
  border: 1px solid #50E3C2;
}
.slider-home__pager ul li a:hover {
  background: #50E3C2;
}
.slider-home__pager ul li a.--accent {
  border: 1px solid #FF203C;
}
.slider-home__pager ul li a.--accent:hover {
  background: #FF203C;
}
.slider-home__pager ul li.active a {
  background: #50E3C2;
}
.slider-home__pager ul li.--accent.active a {
  background: #FF203C;
}

.slider-network {
  padding-right: 140px;
  position: relative;
  margin-bottom: 144px;
  margin-bottom: 9rem;
  display: none;
}
.slider-network ul {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}
.slider-network__slide-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-position: center center;
  width: 60%;
  height: 140px;
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.slider-network__slide-title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}
.slider-network__slide-subtitle {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
  height: 3rem;
}
.slider-network .lSAction {
  position: absolute;
  top: 10px;
  right: 0px;
}
.slider-network .lSAction > a:hover {
  width: 65px;
  background: #50E3C2;
}
.slider-network .lSAction > a:hover:before {
  color: #363636;
}
.slider-network .lSAction > a:before {
  color: white;
  line-height: 65px;
}
.slider-network .lSAction .lSPrev, .slider-network .lSAction .lSNext {
  margin-right: 2px;
  width: 65px;
  height: 65px;
  position: static;
  display: inline-block;
  transform: none;
  margin: 0;
  background: #363636;
  color: white;
}

.breadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
  padding: 0px 24px;
  padding: 0 1.5rem;
  overflow: hidden;
  *zoom: 1;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
}
@media (max-width: 60.74em) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb__item {
  float: left;
  margin-right: 6px;
  margin-right: 0.375rem;
}
.breadcrumb__item:after {
  content: '|';
  display: block;
  float: right;
  margin-left: 6px;
  margin-left: 0.375rem;
}
.breadcrumb__item:last-child {
  font-weight: 700;
}
.breadcrumb__item:last-child:after {
  display: none;
}
.breadcrumb__link {
  text-decoration: none;
}
.breadcrumb__link:hover {
  border-bottom: 1px solid black;
}

.page {
  min-height: 500px;
}
@media (max-width: 60.74em) {
  .page {
    min-height: 0;
  }
}
@media (max-width: 60.74em) {
  .page__sidebar {
    text-align: center;
    margin-bottom: 48px;
    margin-bottom: 3rem;
  }
}
.page__slider {
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
}
@media (max-width: 60.74em) {
  .page__slider {
    padding-top: 24px;
    padding-top: 1.5rem;
  }
}
.page__meta {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.page__image-container {
  background: #000F24;
  transition: all 0.5s ease-in-out;
  margin-bottom: 72px;
  margin-bottom: 4.5rem;
}
.page__image-container img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  filter: grayscale(1);
  mix-blend-mode: screen;
}
.page__meta-separator {
  height: 1px;
  background: #50E3C2;
  width: 45px;
  margin: 24px 0px;
  margin: 1.5rem 0;
}
@media (max-width: 60.74em) {
  .page__meta-separator {
    margin: 24px auto;
    margin: 1.5rem auto;
  }
}
.page__meta-separator--small {
  margin: 12px 0px;
  margin: 0.75rem 0;
}
.page__meta-date {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 900;
}
.page__meta-date span {
  font-size: 13px;
  margin-top: 2px;
  font-weight: 400;
  display: block;
}
.page__meta-title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.page__meta-title--small {
  font-size: 14px;
  font-size: 0.875rem;
}
.page__meta-text {
  font-size: 13px;
  margin-top: 2px;
  font-weight: 400;
  display: block;
}
.page__filters {
  margin: 0px 0px 144px 0px;
  margin: 0 0 9rem 0;
}
@media screen and (max-height: 900px) {
  .page__filters {
    margin: 0px 0px 72px 0px;
    margin: 0 0 4.5rem 0;
  }
}
.page__block-title {
  text-align: left;
}
.page__block {
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
}
.page__block h2:first-child {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
}
.page__block--yellow {
  position: relative;
  background-color: #50E3C2;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  margin: 48px 0px 0px 0px;
  margin: 3rem 0 0 0;
  color: #000F24;
}
.page__block--yellow:before {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  top: -4px;
  position: absolute;
}
.page__block--yellow:after {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  bottom: -4px;
  position: absolute;
}
.page__block--yellow .page__block-title {
  text-align: center;
}
.page__block--yellow a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  position: relative;
  z-index: 1;
  text-decoration: none;
  border-color: #000F24;
  border-bottom: 1px solid #000F24;
  color: #000F24;
  transition: all 0.15s ease-in-out;
}
.page__block--yellow a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: inset 0 -30px #000F24;
  color: #50E3C2;
}
.page__block--white {
  background-color: white;
  color: #000F24;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  margin: 48px 0px 0px 0px;
  margin: 3rem 0 0 0;
  position: relative;
}
.page__block--white:before {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  top: -4px;
  position: absolute;
}
.page__block--white:after {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  bottom: -4px;
  position: absolute;
}
.page__block--white .page__block-title {
  text-align: center;
}
.page__block--white a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  color: #000F24;
  position: relative;
  z-index: 1;
  text-decoration: none;
  border-color: #000F24;
}
.page__block--white a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: none;
  color: #000F24;
}
.page__block--black {
  background-color: #000F24;
  color: white;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  margin: 48px 0px 0px 0px;
  margin: 3rem 0 0 0;
  position: relative;
}
.page__block--black:before {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  top: -4px;
  position: absolute;
}
.page__block--black:after {
  content: "";
  display: block;
  height: 1px;
  background: rgba(80, 227, 194, 0.5);
  width: 100%;
  bottom: -4px;
  position: absolute;
}
.page__block--black .page__block-title {
  text-align: center;
}
.page__block--black a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  color: white;
  position: relative;
  z-index: 1;
  text-decoration: none;
  border-color: #50E3C2;
}
.page__block--black a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: none;
  color: #50E3C2;
}
.page__block--red {
  background-color: red;
  color: white;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  margin: 48px 0px;
  margin: 3rem 0;
  position: relative;
}
.page__block--red:before {
  content: "";
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  top: -4px;
  position: absolute;
}
.page__block--red:after {
  content: "";
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  bottom: -4px;
  position: absolute;
}
.page__block--red .page__block-title {
  text-align: center;
}
.page__block--red a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button) {
  color: white;
  position: relative;
  z-index: 1;
  text-decoration: none;
  border-color: white;
}
.page__block--red a:not(.button):not(.wys-button):not(.wys-button-small):not(.wys-button-xsmall):not(.wys-button-black):not(.wys-button-black-small):not(.wys-button-black-xsmall):not(.wys-button-white):not(.wys-button-white-small):not(.wys-button-white-xsmall):not(.brief-box__button):hover {
  box-shadow: none;
  color: white;
}
.page__block--accent {
  background-color: #FF203C;
  color: #000F24;
  text-align: center;
  font-family: "Merriweather", serif;
  padding: 48px 0px;
  padding: 3rem 0;
}
.page__block--accent h2 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: 0px;
  margin-top: 0;
}
.page__block--accent strong {
  font-weight: 400;
}
.page__block p:last-child {
  margin-bottom: 0;
}
.page--organization {
  margin-top: -80px;
}
.page--organization .map {
  width: 100%;
  height: 950px;
}
.page--search {
  margin-top: 8rem;
}
.page--vertigopage {
  min-height: 0px;
}
.page--extendedcustompage {
  min-height: 0px;
}
.page__item_info {
  width: 100%;
  margin: auto;
  text-align: center;
}
.page__item_selector {
  margin-bottom: 0.3em;
}
.page__item_content {
  border-top: solid 2px #BBBABA;
}
.page__info-block {
  padding-top: 2%;
  color: black;
  display: block;
}
.page__info-block-hidden {
  color: black;
  display: none;
}
.page__option-selected {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Oswald", sans-serif;
  color: #000000;
  margin: 1em;
  padding-bottom: 0.1em;
  border-bottom: 0.2em solid #000000;
  font-size: 20px !important;
}
@media screen and (max-width: 600px) {
  .page__option-selected {
    margin-left: 15px !important;
    font-size: 18px !important;
  }
}
.page__option-unselected {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Oswald", sans-serif;
  color: #767D86;
  margin: 1em !important;
  font-size: 20px !important;
}
@media screen and (max-width: 600px) {
  .page__option-unselected {
    margin-left: 15px !important;
    font-size: 18px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
}
.page__margin-top {
  margin-top: 0px !important;
}
@media screen and (max-width: 600px) {
  .page__margin-top {
    margin-top: -75px !important;
  }
}
.page__padding-settings-form {
  padding-left: 30%;
  padding-right: 30%;
}
.page__button-logout {
  background: #50e3c2;
  color: white;
  width: 50%;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
  border: 1px solid #50e3c2;
}
.page__row-app-form {
  display: table;
  width: 100%;
  text-align: center;
  padding-top: 0%;
}
@media screen and (max-width: 600px) {
  .page__row-app-form {
    padding-top: 4%;
  }
}
.page__column-space {
  padding-top: 0.5%;
}
.page__column-app-form {
  float: left;
  width: 33.33%;
  display: inline-block;
  display: block;
}
@media screen and (max-width: 600px) {
  .page__column-app-form {
    width: 100%;
  }
}
.page__inner-row-content {
  text-align: left;
  width: 50%;
  margin-left: 50%;
}
@media screen and (max-width: 600px) {
  .page__inner-row-content {
    margin-left: 5%;
  }
}
.page__button-row-profile-view {
  text-align: left;
  width: 30%;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: black;
  background: white;
  text-align: center;
  margin-left: 50%;
  font-family: "Oswald", sans-serif;
  font-size: 1.1em;
  margin-top: 1%;
  display: block;
  transform: translate(0px, 0px);
  float: left;
}
@media screen and (max-width: 600px) {
  .page__button-row-profile-view {
    width: 40%;
    float: right;
    transform: translate(0px, -120%);
  }
}
.page__button-row-profile-edit {
  text-align: left;
  width: 30%;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: black;
  background: #50e3c2;
  text-align: center;
  margin-left: 50%;
  font-family: "Oswald", sans-serif;
  font-size: 1.1em;
  margin-top: 1%;
  display: block;
  transform: translate(0px, 0px);
  float: left;
}
@media screen and (max-width: 600px) {
  .page__button-row-profile-edit {
    width: 40%;
    float: right;
    transform: translate(0px, -120%);
  }
}
.page__row-app-space-form {
  margin-top: 1%;
}
.page__row-app-space-bottom-form {
  margin-bottom: 2%;
}
.page__inner-item-bold {
  color: black;
  font-weight: bold;
  font-size: 1em;
  font-family: 'Helvetica';
}
.page__inner-item {
  color: black;
  font-size: 1em;
  font-family: 'Helvetica';
}
.page__inner-item-red {
  color: red;
  font-size: 1em;
  font-family: 'Helvetica';
}
.page__center-bio {
  text-align: center;
  color: white;
  font-size: 1em;
  font-family: 'Helvetica';
}
.page__clear-color {
  color: white !important;
}
.page__space-row {
  margin-left: 3%;
}
.page__row-responsive {
  display: inline-block !important;
}
@media screen and (max-width: 480px) {
  .page__row-responsive {
    transform: scale(0.73) !important;
    display: inline-block !important;
    margin-left: -21.5% !important;
    margin-top: -40% !important;
  }
}
@media screen and (max-width: 780px) {
  .page__row-responsive {
    transform: scale(0.73) !important;
    display: inline-block !important;
    margin-left: -15% !important;
    margin-top: -40% !important;
  }
}
@media screen and (max-width: 320px) {
  .page__row-responsive {
    transform: scale(0.73) !important;
    display: inline-block !important;
    margin-left: -21% !important;
    margin-top: -40% !important;
  }
}
.page__space-responsive {
  margin-top: 0% !important;
}
@media screen and (max-width: 780px) {
  .page__space-responsive {
    margin-top: -60% !important;
  }
}

.pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: right;
  font-family: "Oswald", sans-serif;
  margin: 0px 0px 48px 0px;
  margin: 0 0 3rem 0;
}
.pagination__item {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0px 2.4px;
  margin: 0 0.15rem;
}
.pagination__link {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  margin: 0px;
  margin: 0;
  font-weight: 400;
  display: block;
  background: #001B42;
  color: white;
  border: 1px solid black;
  padding: 3.6px 12px;
  padding: 0.225rem 0.75rem;
  transition: all 0.25s ease-in-out;
}
.pagination__link.active, .pagination__link:hover {
  background: #002C6B;
  color: white;
}

.lang-switcher {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-right: 12px;
  padding-right: 0.75rem;
  position: absolute;
  top: 0;
  right: .25rem;
  z-index: 2;
}
.lang-switcher__item {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-family: "Oswald", sans-serif;
  padding: 0px 12px;
  padding: 0 0.75rem;
  text-transform: uppercase;
  display: none;
}
.lang-switcher__item > a {
  color: white;
}
.lang-switcher__item.active {
  display: block;
}
.lang-switcher.open .lang-switcher__item {
  display: block;
  background: #1D1D1B;
}
.lang-switcher.open:after {
  transform: rotate(180deg);
}
.lang-switcher:after {
  display: block;
  content: '\f107';
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-family: FontAwesome;
  font-size: 0.8em;
  line-height: 34px;
  line-height: 2.125rem;
  margin-right: 12px;
  margin-right: 0.75rem;
  transition: all 0.2s ease-in-out;
}
.sidebar .lang-switcher {
  top: 15px;
}
.sidebar .lang-switcher .lang-switcher__item {
  display: inline-block;
  padding: 0px 6px;
  padding: 0 0.375rem;
}
.sidebar .lang-switcher .lang-switcher__item > a {
  color: black;
}

.role-switcher {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-right: 12px;
  padding-right: 0.75rem;
  position: relative;
  z-index: 2;
}
.role-switcher__item {
  font-size: 16px;
  font-size: 1rem;
  line-height: 34px;
  line-height: 2.125rem;
  font-family: "Oswald", sans-serif;
  padding: 0px 12px;
  padding: 0 0.75rem;
  width: 120px;
  margin-left: -30px;
  position: absolute;
  display: none;
}
.role-switcher__item > a {
  color: white;
}
.role-switcher__item > a:hover {
  color: #50E3C2;
}
.role-switcher__item.active {
  display: block;
}
.role-switcher__item:first-child {
  margin-left: 0px;
  width: auto;
  position: static;
  padding: 0px;
  padding: 0;
}
.role-switcher__item:first-child > a:hover {
  color: white;
}
.role-switcher__item:first-child:after {
  display: block;
  content: '\f107';
  color: white;
  position: absolute;
  top: 2px;
  right: -13px;
  font-family: FontAwesome;
  font-size: 0.8em;
  line-height: 34px;
  line-height: 2.125rem;
  margin-right: 12px;
  margin-right: 0.75rem;
  transition: all 0.2s ease-in-out;
}
.role-switcher__item:nth-child(2) {
  top: 34px;
}
.role-switcher__item:nth-child(3) {
  top: 68px;
}
.role-switcher__item:nth-child(4) {
  top: 102px;
}
.role-switcher__item:nth-child(5) {
  top: 136px;
}
.role-switcher__item:nth-child(6) {
  top: 170px;
}
.role-switcher__item:nth-child(7) {
  top: 204px;
}
.role-switcher__item:nth-child(8) {
  top: 238px;
}
.role-switcher.open .role-switcher__item {
  display: block;
  background: #1D1D1B;
}
.role-switcher.open .role-switcher__item:first-child:after {
  transform: rotate(180deg);
}
.sidebar .role-switcher:before {
  display: block;
  content: "";
  background: black;
  width: 240px;
  height: 6px;
  margin: 36px 0px 24px 0px;
  margin: 2.25rem 0 1.5rem 0;
}
.sidebar .role-switcher .role-switcher__item {
  position: static;
  margin-left: 0;
  display: block;
  padding: 0;
  margin: 0px 0px 12px 0px;
  margin: 0 0 0.75rem 0;
}
.sidebar .role-switcher .role-switcher__item:first-child {
  display: none;
}
.sidebar .role-switcher .role-switcher__item > a {
  color: black;
  font-size: 20px;
  font-size: 1.25rem;
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: rgba(0, 15, 36, 0.95);
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  -moz-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -o-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -webkit-transition: opacity 0.5s ease-in-out, height 0s;
  -webkit-transition-delay: 0s, 0.5s;
  transition: opacity 0.5s ease-in-out, height 0s 0.5s;
}
.search.open {
  height: 100%;
  display: table;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, height 0s;
}
.search__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 2;
  color: white;
  display: block;
  width: 40px;
  height: 40px;
}
.search__close:before, .search__close:after {
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background: white;
  position: absolute;
  top: 20px;
  left: 0;
  transition: all 0.5s ease-in-out;
}
.search__close:before {
  transform: rotate(45deg);
}
.search__close:after {
  transform: rotate(-45deg);
}
.search__close:hover:before {
  background: #50E3C2;
  transform: rotate(225deg);
}
.search__close:hover:after {
  background: #50E3C2;
  transform: rotate(-225deg);
}
.search__container {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.search__content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.search-form input[type="text"] {
  border: 0;
  outline: none;
  background: none;
  border-bottom: 1px solid #50E3C2;
  padding: 1rem 3rem 1rem 0;
  width: 100%;
  color: white;
  font-size: 30px;
  font-size: 1.875rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
}
@media (max-width: 60.74em) {
  .search-form input[type="text"] {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.search-form button[type="submit"] {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: 0;
  background: none;
  outline: 0;
  cursor: pointer;
  color: white;
  font-size: 30px;
  font-size: 1.875rem;
}

.pattern-bg {
  position: relative;
}
.pattern-bg:after {
  content: "";
  background-repeat: repeat;
  background-position: top left;
  opacity: 1.0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.pattern-bg--circles:after {
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.pattern-bg--squares:after {
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.pattern-bg--stripes:after {
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.pattern-bg--triangles:after {
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}

.pattern-over {
  position: relative;
}
.pattern-over:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  background-position: top left;
  opacity: 0.2;
}
.pattern-over--circles:after {
  background-image: url(../img/patterns/circles.png);
  opacity: 0.2;
}
.pattern-over--squares:after {
  background-image: url(../img/patterns/squares.png);
  opacity: 0.4;
}
.pattern-over--stripes:after {
  background-image: url(../img/patterns/stripes.png);
  opacity: 0.4;
}
.pattern-over--triangles:after {
  background-image: url(../img/patterns/triangles.png);
  opacity: 0.2;
}

.tag {
  display: inline-block;
  background: white;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 33.6px;
  line-height: 2.1rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  /*& + .tag {
      margin-left: -0.25em;
  }*/
}
.tag--small {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding-right: 12px;
  padding-right: 0.75rem;
}
.tag--xsmall {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 24px;
  line-height: 1.5rem;
  padding-right: 6px;
  padding-right: 0.375rem;
}
.tag--category {
  background: black;
  color: white;
  padding-left: 12px;
  padding-left: 0.75rem;
  padding-right: 12px;
  padding-right: 0.75rem;
}

.share-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-top: 0;
}
.share-links__item {
  display: block;
  font-size: 2rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  color: white;
}
@media (max-width: 73.24em) {
  .share-links__item {
    display: inline-block;
    margin-right: 12px;
    margin-right: 0.75rem;
  }
}
.share-links__item a {
  color: white;
  transition: all 0.5s ease-in-out;
}
.share-links__item a:hover {
  color: #50E3C2;
}
.overlay .share-links .share-links__item {
  display: inline-block;
  margin-right: 6px;
  margin-right: 0.375rem;
  font-size: 1.3rem;
}

.partners-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.partners-list__item {
  display: inline-block;
  width: 150px;
  height: 100px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 24px 48px 0px 0px;
  margin: 1.5rem 3rem 0 0;
}
.partners-list__item img {
  display: none;
}

.banner {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 48px;
  margin-top: 3rem;
}
@media (max-width: 89.99em) {
  .banner {
    height: 250px;
  }
}
@media (max-width: 60.74em) {
  .banner {
    height: 250px;
  }
}
@media (max-width: 46.99em) {
  .banner {
    height: auto;
  }
}
.banner:hover .banner__title:after {
  opacity: 1;
  transform: scaleX(1);
}
.banner--colored:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.banner--blue:after {
  background: #009DD2;
  opacity: 0.7;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.banner--green:after {
  background: #0D9570;
  opacity: 0.69;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.banner--orange:after {
  background: #FDA618;
  opacity: 0.5;
}
.banner--light {
  color: white;
}
.banner--dark {
  color: #1D1D1B;
}
.banner--sidebar {
  margin-top: 0;
  margin-bottom: 48px;
  margin-bottom: 3rem;
  height: 150px;
}
.banner--sidebar .banner__title {
  line-height: 48px;
  line-height: 3rem;
}
.banner--sidebar .banner__content {
  padding: 24px;
  padding: 1.5rem;
}
.banner__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 24px;
  padding: 1.5rem;
  padding-top: 120px;
  text-align: center;
}
@media (max-width: 60.74em) {
  .banner__content {
    height: auto;
    position: relative;
  }
}
@media (max-width: 89.99em) {
  .banner__content {
    padding-top: 36px;
  }
}
@media (max-width: 73.24em) {
  .banner__content {
    padding-top: 25px;
  }
}
@media (max-width: 60.74em) {
  .banner__content {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
.banner__title {
  line-height: 28.8px;
  line-height: 1.8rem;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  display: inline-block;
  position: relative;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
}
.banner__title:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  height: 10px;
  left: -10px;
  right: -10px;
  background: white;
  z-index: -1;
  opacity: 0;
  transform: scaleX(0);
  transition: all 0.25s ease-in-out;
}
.banner--light .banner__title:after {
  background: #1D1D1B;
}
.banner--dark .banner__title:after {
  background: white;
}
.banner__desc {
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.messages {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.messages__item {
  background: #50E3C2;
  color: #1D1D1B;
  padding: 12px 24px;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
}
.messages__item.info {
  background: #50E3C2;
  color: #1D1D1B;
}

.project-details__title {
  text-align: left;
  padding-left: 48px;
  padding-left: 3rem;
  margin-left: 72px;
  margin-top: 0px;
  margin-top: 0;
}
@media (max-width: 89.99em) {
  .project-details__title {
    margin-left: 72px;
  }
}
@media (max-width: 73.24em) {
  .project-details__title {
    margin-left: 72px;
  }
}
@media (max-width: 60.74em) {
  .project-details__title {
    margin-left: 0;
    padding-left: 0;
  }
}
.project-details__item {
  overflow: hidden;
  *zoom: 1;
  margin: 0px 0px 12px 0px;
  margin: 0 0 0.75rem 0;
}
.project-details__item-title {
  font-weight: 700;
  float: left;
  clear: both;
  width: 100px;
  text-align: right;
  margin-right: 18px;
}
@media (max-width: 89.99em) {
  .project-details__item-title {
    width: 100px;
  }
}
@media (max-width: 73.24em) {
  .project-details__item-title {
    width: 100px;
  }
}
@media (max-width: 60.74em) {
  .project-details__item-title {
    float: none;
    text-align: left;
  }
}
.project-details__item-desc {
  float: left;
  width: 380px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
}
@media (max-width: 89.99em) {
  .project-details__item-desc {
    width: 360px;
  }
}
@media (max-width: 73.24em) {
  .project-details__item-desc {
    width: 330px;
  }
}
@media (max-width: 60.74em) {
  .project-details__item-desc {
    float: none;
  }
}
.project-details__logo {
  clear: both;
  padding-left: 0px;
  padding-left: 0;
  margin-left: 200px;
  text-align: left;
}
@media (max-width: 89.99em) {
  .project-details__logo {
    margin-left: 150px;
  }
}
@media (max-width: 73.24em) {
  .project-details__logo {
    margin-left: 150px;
  }
}
@media (max-width: 60.74em) {
  .project-details__logo {
    margin-left: 0;
    margin-bottom: 48px;
    margin-bottom: 3rem;
  }
}

.map-infowindow {
  padding: 24px;
  padding-right: 1px;
  overflow: hidden;
  *zoom: 1;
  color: #000F24;
}
.map-infowindow__content {
  text-align: center;
  max-width: 200px;
}
.map-infowindow__content p {
  margin: 0px;
  margin: 0;
  line-height: 1.1rem;
}
.map-infowindow__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.map-infowindow__subtitle {
  margin-top: 6px;
  margin-top: 0.375rem;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
}
.map-infowindow__subtitle + p {
  margin-top: 12px;
  margin-top: 0.75rem;
}
.map-infowindow__image {
  width: 140px;
  margin: 0 auto;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  text-align: center;
}
.map-infowindow__image img {
  width: 140px;
  height: auto;
}

.map-infowindow-residencies {
  overflow: hidden;
  *zoom: 1;
  color: #000F24;
}
.map-infowindow-residencies__content {
  text-align: center;
  max-width: 200px;
}
.map-infowindow-residencies__content p {
  margin: 0px;
  margin: 0;
  line-height: 1.1rem;
}
.map-infowindow-residencies__title {
  font-size: 16px;
  font-size: 1rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.map-infowindow-residencies__subtitle {
  margin-top: 6px;
  margin-top: 0.375rem;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
}
.map-infowindow-residencies__subtitle + p {
  margin-top: 12px;
  margin-top: 0.75rem;
}

.map-legend {
  padding: 12px;
  padding: 0.75rem;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 10;
  background: white;
  border: 1px solid black;
}
@media (max-width: 46.99em) {
  .map-legend {
    display: none;
  }
}
.map-legend__item {
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
  color: #1D1D1B;
  display: inline-block;
  line-height: 22px;
  margin-right: 12px;
  margin-right: 0.75rem;
}
.map-legend__item:last-child {
  margin-right: 0px;
  margin-right: 0;
}
.map-legend__item:before {
  content: "";
  float: left;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 12px;
  margin-right: 0.75rem;
}
.map-legend__item--red:before {
  background-image: url(../img/map-legend-red.png);
}
.map-legend__item--blue:before {
  background-image: url(../img/map-legend-blue.png);
}
.map-legend__item--green:before {
  background-image: url(../img/map-legend-green.png);
}

.linked-organizations {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: -45px !important;
}
.linked-organizations__item {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0px 24px 0px 0px;
  margin: 0 1.5rem 0 0;
}
.linked-organizations__item a {
  display: inline-block;
}

.newsletter {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
@media (max-width: 60.74em) {
  .newsletter {
    margin-top: 48px;
    margin-top: 3rem;
  }
}
.newsletter__text {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.newsletter__input {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  width: 100%;
  outline: 0;
  background: transparent;
  border: 0;
  color: white;
  border-bottom: 1px solid white;
}
.newsletter__button {
  color: white;
  outline: 0;
  background: transparent;
  border: 0;
  position: absolute;
  top: 0px;
  right: 7px;
  font-size: 20px;
  font-size: 1.25rem;
}

.tweets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tweets__tweet {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.tweets__tweet-image {
  width: 100%;
  border-radius: 10px;
}
.tweets__tweet-content {
  text-align: left;
}
.tweets__tweet-author {
  margin: 0;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  color: white;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
}
.tweets__tweet-author a {
  display: inline-block;
  margin-left: 12px;
  margin-left: 0.75rem;
  color: white;
}
.tweets__tweet-author a:hover {
  color: #50E3C2;
}
.tweets__tweet-author span {
  display: inline-block;
  margin-left: 12px;
  margin-left: 0.75rem;
  font-weight: 300;
}
.tweets__tweet-body {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 300;
}
.tweets__tweet-body a {
  display: inline-block;
  margin-right: 6px;
  margin-right: 0.375rem;
  color: white;
  font-weight: 400;
}
.tweets__tweet-body a:hover {
  color: #50E3C2;
}

.instagram__item {
  display: block;
  float: left;
  width: 33.33%;
}
.instagram__item span {
  display: none;
}

.hero {
  background-image: url(../img/vertigo/hero.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 560px;
  margin-bottom: 350px;
  position: relative;
}
.hero.large-title {
  margin-bottom: 500px;
}
.hero.large-title .hero__title {
  margin: 0 auto;
  position: relative;
}
.hero.large-title .hero__title img {
  max-width: 73%;
}
@media (max-width: 89.99em) {
  .hero.large-title .hero__title {
    padding-top: 60px;
  }
}
@media (max-width: 73.24em) {
  .hero.large-title .hero__title {
    padding-top: 97px;
  }
}
@media (max-width: 60.74em) {
  .hero.large-title .hero__title {
    padding-top: 115px;
  }
}
@media (max-width: 46.99em) {
  .hero.large-title .hero__title {
    padding-top: 120px;
  }
}
@media (max-width: 89.99em) {
  .hero.large-title {
    margin-bottom: 450px;
  }
}
@media (max-width: 73.24em) {
  .hero.large-title {
    margin-bottom: 350px;
  }
}
@media (max-width: 60.74em) {
  .hero.large-title {
    margin-bottom: 300px;
  }
}
@media (max-width: 46.99em) {
  .hero.large-title {
    margin-bottom: 90px;
  }
}
@media (max-width: 46.99em) {
  .hero {
    height: 346px;
    margin-bottom: 140px;
  }
}
.hero .Background {
  position: absolute;
  top: 0;
  left: 0;
  height: 563px;
  width: 100%;
}
@media (max-width: 46.99em) {
  .hero .Background {
    height: 346px;
  }
}
.hero:after {
  content: '';
  height: 1px;
  width: 30px;
  background: #F2EDDC;
  position: absolute;
  top: 825px;
  left: 50%;
  margin-left: -15px;
}
@media (max-width: 46.99em) {
  .hero:after {
    top: 440px;
  }
}
.hero__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(80%);
}
@media (max-width: 60.74em) {
  .hero__title img {
    width: 70%;
  }
}
@media (max-width: 46.99em) {
  .hero__title img {
    width: 231px;
  }
}
.hero__logo {
  margin-top: 48px;
  margin-top: 3rem;
  text-align: center;
}
@media (max-width: 46.99em) {
  .hero__logo {
    margin-top: 0px;
    margin-top: 0;
  }
  .hero__logo img {
    width: 159px;
  }
}
.hero__left {
  margin-top: 96px;
  margin-top: 6rem;
  text-align: left;
}
@media (max-width: 46.99em) {
  .hero__left {
    margin-top: 24px;
    margin-top: 1.5rem;
    text-align: center;
  }
  .hero__left img {
    width: 190px;
  }
}
.hero__right {
  margin-top: 96px;
  margin-top: 6rem;
  text-align: right;
}
@media (max-width: 46.99em) {
  .hero__right {
    margin-top: 12px;
    margin-top: 0.75rem;
    text-align: center;
  }
  .hero__right img {
    width: 50px;
  }
}
.hero__date {
  display: block;
  margin-top: 48px;
  margin-top: 3rem;
  margin-left: 48px;
  margin-left: 3rem;
}
@media (max-width: 46.99em) {
  .hero__date {
    display: block;
    text-align: center;
    width: 120px !important;
    margin: 0px auto;
    margin: 0 auto;
    transform: translateY(-20px) translateX(-14px);
  }
}
.hero--small {
  margin-bottom: 96px;
  margin-bottom: 6rem;
}
@media (max-width: 46.99em) {
  .hero--small {
    margin-bottom: 96px;
    margin-bottom: 6rem;
  }
}
.hero--small:after {
  display: none;
}
.hero--small .hero__logo {
  margin-top: 360px;
  margin-top: 22.5rem;
  text-align: center;
}
@media (max-width: 46.99em) {
  .hero--small .hero__logo {
    margin-top: 192px;
    margin-top: 12rem;
  }
  .hero--small .hero__logo img {
    width: 159px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  background: rgba(0, 15, 36, 0.95);
  display: block;
  height: 0;
  opacity: 0;
  -moz-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -o-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -webkit-transition: opacity 0.5s ease-in-out, height 0s;
  -webkit-transition-delay: 0s, 0.5s;
  transition: opacity 0.5s ease-in-out, height 0s 0.5s;
}
.overlay.open {
  height: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, height 0s;
}
.overlay__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 2;
  color: white;
  display: block;
  width: 40px;
  height: 40px;
}
.overlay__close:before, .overlay__close:after {
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  background: white;
  position: absolute;
  top: 20px;
  left: 0;
  transition: all 0.5s ease-in-out;
}
.overlay__close:before {
  transform: rotate(45deg);
}
.overlay__close:after {
  transform: rotate(-45deg);
}
.overlay__close:hover:before {
  background: #50E3C2;
  transform: rotate(225deg);
}
.overlay__close:hover:after {
  background: #50E3C2;
  transform: rotate(-225deg);
}
.overlay__container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: scroll;
}
.overlay__content {
  padding: 120px 0px;
  padding: 7.5rem 0;
}
.overlay #overlayContent {
  opacity: 0;
  -moz-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -o-transition: opacity 0.5s ease-in-out, height 0s 0.5s;
  -webkit-transition: opacity 0.5s ease-in-out, height 0s;
  -webkit-transition-delay: 0s, 0.5s;
  transition: opacity 0.5s ease-in-out, height 0s 0.5s;
}
.overlay #overlayContent.loaded {
  opacity: 1;
}
.overlay__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  margin-left: -30px;
  margin-top: -30px;
}
.overlay__loader .loading {
  height: 60px;
  width: 60px;
  display: block;
  animation: rotation 1s infinite linear;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  margin: auto;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
.page--live-streaming .intro {
  margin-bottom: 30px;
}
.page--live-streaming #video_container {
  height: 720px;
}
.page--live-streaming .page__content {
  text-align: center;
  height: 720px;
  padding-left: 70px;
  padding-right: 70px;
}
.page--live-streaming iframe {
  height: 100%;
  max-height: 720px;
}
@media (max-width: 60.74em) {
  .page--live-streaming iframe {
    max-height: 690px;
  }
}
.page--live-streaming .countdown-overlay {
  background: #000000 none repeat scroll 0 0;
  color: white;
  font-family: "Oswald",sans-serif;
  font-size: 2em;
  height: 100%;
  max-height: 720px;
  padding-left: 70px;
  padding-right: 70px;
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
  z-index: 90;
}
.page--live-streaming #countdown-title {
  margin-top: 5em;
}
.page--live-streaming #countdown {
  margin-top: 1em;
  font-weight: bold;
  color: white;
}

.call__info {
  width: 100%;
  margin: auto;
  text-align: center;
}
.call__workflow {
  margin-top: 2em;
  margin-bottom: 4em;
}
.call__selector {
  margin-bottom: 1em;
}
.call__content {
  border: solid 1px #FFFFFF;
}
.call__info-block {
  display: block;
}
.call__info-block-hidden {
  display: none;
}
.call__all {
  margin: 1em;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  font-family: "Oswald",sans-serif !important;
}
.call__artist-selected {
  color: #FF203C;
  padding-bottom: 0.6em;
  border-bottom: 0.2em solid #FF203C;
}
.call__artist-unselected {
  color: #a00014;
}
.call__project-selected {
  color: #50E3C2;
  padding-bottom: 0.6em;
  border-bottom: 0.2em solid #50E3C2;
}
.call__project-unselected {
  color: #199b7e;
}
.call__producer-selected {
  color: #FFFFFF;
  padding-bottom: 0.6em;
  border-bottom: 0.2em solid #FFFFFF;
}
.call__producer-unselected {
  color: #bfbfbf;
}
.call__steps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.call__main_info {
  text-align: left;
  margin-top: 4em;
  margin-bottom: 4em;
}
.call__subcall_info {
  text-align: left;
  padding: 1em 4em 1em 4em;
}
.call__button {
  width: 12em;
  height: 6em;
  border-radius: 0.3em;
  border-style: solid;
  border-width: 1em;
  padding: 0.3em;
  margin: 2em;
  cursor: pointer;
  font-family: "Oswald",sans-serif !important;
}
.call__button-artist {
  color: #FFFFFF;
  border-color: #FF203C;
  background-color: #FF203C;
}
.call__button-project {
  color: #1D1D1B;
  border-color: #50E3C2;
  background-color: #50E3C2;
}
.call__button-producer {
  color: #1D1D1B;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.call__button_text_top {
  font-weight: bold;
  font-size: 1.5em;
}
.call__button_text_bottom {
  font-size: 0.8em;
}
@media screen and (max-width: 780px) {
  .call__button {
    margin: 1em;
  }
}
@media screen and (max-width: 780px) {
  .call__responsive-item {
    transform: scale(1.2) !important;
    margin-bottom: 1.5em !important;
  }
}
@media screen and (max-width: 780px) {
  .call__selector-responsive-item {
    font-size: 12px !important;
    margin-right: 0.5em !important;
  }
}
@media screen and (max-width: 780px) {
  .call__call-step-project-responsive {
    transform: scale(0.6) !important;
    font-size: 16px !important;
  }
}
@media screen and (max-width: 780px) {
  .call__call-step-artist-responsive {
    transform: scale(0.4) !important;
    font-size: 15px !important;
  }
}
@media screen and (max-width: 780px) {
  .call__responsive-text-section {
    font-size: 15px;
    width: 350px;
    margin-left: -40px;
  }
}

.project__selector {
  width: 100%;
  margin: auto;
  margin-bottom: 3em;
  text-align: center;
}
.project__list-block {
  display: block;
}
.project__list-block-hidden {
  display: none;
}
.project__type__all {
  margin: 1em;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  font-family: "Oswald",sans-serif !important;
}
.project__type-selected {
  color: #50E3C2;
  padding-bottom: 0.6em;
  border-bottom: 0.2em solid #50E3C2;
}
.project__type-unselected {
  color: #199b7e;
}

.project-ict-detail__title {
  margin: auto;
  margin-bottom: 1em;
  text-align: center;
  font-size: 1.875em;
  font-weight: bold;
  line-height: 2.25rem;
  font-family: Oswald,sans-serif;
}
.project-ict-detail__slider {
  margin: auto;
  margin-bottom: 2em;
  text-align: center;
}
.project-ict-detail__tag-style-simple {
  display: inline-block;
  border: 0.2em solid #50E3C2;
  border-radius: .2em;
  color: #50E3C2;
  font-size: 1.2em;
  margin: .5em;
  padding: .2em .5em;
}
@media screen and (max-width: 780px) {
  .project-ict-detail__tag-style-simple {
    font-size: 9px;
    padding: 0.5em;
  }
}
.project-ict-detail__main_info {
  margin: auto;
  margin-bottom: 1em;
  text-align: center;
  font-size: 0.8em;
  color: #FFFFFF;
}
.project-ict-detail__main_info-description {
  margin-bottom: 1em;
}
.project-ict-detail__main_info-website {
  margin-bottom: 1em;
}
.project-ict-detail__main_info-website > a {
  color: #FFFFFF;
  border-bottom: 2px solid #50E3C2;
}
.project-ict-detail__main_info-website > a:hover {
  color: #1D1D1B;
  border: 2px solid #50E3C2;
  background-color: #50E3C2;
}
.project-ict-detail__main_info-date {
  margin-bottom: 1em;
}
.project-ict-detail__main_info-keywords {
  margin: 3em auto 0em auto;
  width: 75%;
  font-size: 12px;
  padding: 1em;
}
.project-ict-detail__main_info-keywords-tags {
  display: inline-block;
  border: solid 0.2em #50E3C2;
  border-radius: 0.2em;
  color: #50E3C2;
  font-size: 1.2em;
  margin: 0.5em;
  padding: 0.2em 0.5em;
}
.project-ict-detail__main_info-keywords-tags:hover {
  background-color: #50E3C2;
  color: #1D1D1B;
}
@media screen and (max-width: 780px) {
  .project-ict-detail__main_info-keywords {
    width: 100%;
    font-size: 9px;
    margin: 3em auto 0em auto;
    padding: 0.5em;
  }
}
.project-ict-detail__apply_button {
  margin: auto;
  margin-bottom: 1em;
  width: 6em;
  height: 4em;
  color: #FFFFFF;
  border-color: #FF203C;
  background-color: #FF203C;
  border-radius: 0.5em;
  border-style: solid;
  border-width: 1em;
  padding: 0.3em;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.25em;
  font-family: "Oswald",sans-serif !important;
  text-align: center;
}
.project-ict-detail__extra_info {
  margin: auto;
  margin-bottom: 2em;
}
.project-ict-detail__extra_color_item {
  background: white;
  color: black;
  padding: 2em;
}
.project-ict-detail__green-top-line {
  border-top: 3px solid #50E3C2;
  padding-top: 1px;
}
.project-ict-detail__editable {
  margin: auto;
  margin-bottom: 2.5em;
  padding: 1em;
  width: 50%;
  text-align: center;
  font-weight: bold;
  color: #FFFFFF;
  border-color: #FF203C;
  border-style: solid;
  border-width: 0.5em;
  line-height: 2em;
}
.project-ict-detail__edit_button {
  margin: auto;
  margin-top: 0.5em;
  color: #FFFFFF;
  border-color: #50E3C2;
  background-color: #50E3C2;
  border-style: solid;
  border-width: 0.1em;
  padding: 0.5em 1.5em;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.25em;
  font-family: "Oswald",sans-serif !important;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.project-ict-detail__timeline {
  color: #50E3C2;
  position: relative;
  width: 75%;
  height: 5em;
  margin: 4em auto;
}
.project-ict-detail__timeline-step-left {
  position: absolute;
  width: 10em;
  text-align: center;
  margin: auto;
  left: 0;
}
.project-ict-detail__timeline-step-center {
  position: absolute;
  width: 10em;
  text-align: center;
  margin: auto;
  left: 50%;
  margin-left: -5em;
}
.project-ict-detail__timeline-step-right {
  position: absolute;
  width: 10em;
  text-align: center;
  margin: auto;
  right: 0;
}
.project-ict-detail__timeline-divisor {
  padding: 0;
  margin: 0;
  height: 0;
  background-color: #50E3C2;
  line-height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.project-ict-detail__timeline-divisor-incomplete {
  padding: 0;
  margin: 0;
  height: 0;
  background-color: #0e5948;
  line-height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.project-ict-detail__timeline-incomplete {
  color: #0e5948;
}
.project-ict-detail__timeline-complete {
  color: #000F24;
}
.project-ict-detail__divider-chevron-section {
  display: inline-block;
  text-align: center;
  color: #50e3c2;
  font-size: 30px;
  float: center;
  margin-top: 5px;
}
.project-ict-detail__single-separator {
  margin-top: 0.3em;
}
@media screen and (max-width: 780px) {
  .project-ict-detail__responsive-tree {
    width: 120% !important;
    margin-left: -12% !important;
  }
}
@media screen and (max-width: 780px) {
  .project-ict-detail__responsive-tree-text {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 780px) {
  .project-ict-detail__responsive-width-section {
    margin-left: -4% !important;
    margin-right: -4% !important;
  }
}

.dashed {
  position: relative;
  display: inline-block;
  z-index: 1;
  padding-right: 48px;
  padding-right: 3rem;
  /*&:after {
      position: absolute;
      height: 40%;
      width: 100%;
      display: block;
      top: 30%;
      left: 0;
      content: "";
      background: $color-main;
      z-index: -1;
  }*/
  background-image: linear-gradient(rgba(0, 0, 0, 0) 14px, #50e3c2 14px, #50e3c2 34px, rgba(0, 0, 0, 0) 34px);
  background-repeat: repeat;
  background-size: 16px 48px;
}
.dashed:before {
  content: '.';
}
.dashed.tag {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 10px, #50e3c2 10px, #50e3c2 23px, rgba(0, 0, 0, 0) 23px);
  background-repeat: repeat;
  background-size: 16px 33px;
}
.dashed--gray:after {
  background: #C3C3C3;
}
.dashed--center {
  padding: 0px 12px;
  padding: 0 0.75rem;
}
.dashed--center:before {
  display: none;
}
.dashed--small {
  padding-right: 12px;
  padding-right: 0.75rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 7px, #50e3c2 7px, #50e3c2 16px, rgba(0, 0, 0, 0) 16px);
  background-repeat: repeat;
  background-size: 16px 24px;
}
.dashed.tag--small {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 7px, #50e3c2 7px, #50e3c2 16px, rgba(0, 0, 0, 0) 16px);
  background-repeat: repeat;
  background-size: 16px 24px;
}

.dotted {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.dotted:before {
  content: '.';
}

.blockquote,
blockquote {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 36px;
  line-height: 2.25rem;
  font-family: "Merriweather", serif;
  margin-top: 72px;
  margin-top: 4.5rem;
  margin-bottom: 72px;
  margin-bottom: 4.5rem;
  margin-left: 24px;
  margin-left: 1.5rem;
  padding-left: 24px;
  padding-left: 1.5rem;
  font-weight: 900;
  position: relative;
}
.blockquote:before,
blockquote:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
}
.blockquote--small,
blockquote--small {
  margin-left: 12px;
  margin-left: 0.75rem;
  padding-left: 12px;
  padding-left: 0.75rem;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 30px;
  line-height: 1.875rem;
}

.well {
  width: 100%;
  background: #50E3C2;
  padding: 48px 0px;
  padding: 3rem 0;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 40.8px;
  line-height: 2.55rem;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.flash {
  background: #50E3C2;
  overflow: hidden;
  *zoom: 1;
}
.flash__title {
  float: left;
}
.flash__text {
  float: left;
}

.section-title {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: white;
  display: inline-block;
  line-height: 1.5em;
  margin-bottom: 96px;
  margin-bottom: 6rem;
  margin-top: 120px;
  margin-top: 7.5rem;
}
@media (max-width: 73.24em) {
  .section-title {
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .page__slider .section-title {
    margin-top: 0px;
    margin-top: 0;
  }
}
.section-title i {
  display: inline-block;
  margin-right: 12px;
  margin-right: 0.75rem;
  transition: all 0.5s ease-in-out;
}
.section-title a {
  color: inherit;
  transition: all 0.5s ease-in-out;
}
.section-title a:hover {
  color: #50E3C2;
}
.section-title a:hover i {
  transform: translateX(-50%);
}
.section-title--uppercase {
  text-transform: uppercase;
}
.section-title--underline {
  border-bottom: 1px solid white;
}
.section-title--dark {
  color: #000F24;
}
.section-title--accent {
  border-color: #FF203C;
}
.section-title--accent:before {
  color: #FF203C;
}
.section-title--main {
  border-color: #50E3C2;
}
.section-title--main:before {
  color: #50E3C2;
}
.section-title:before {
  content: '_';
  display: block;
  text-decoration: none !important;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.section-title--big {
  color: #50E3C2;
  font-size: 42px;
  font-size: 2.625rem;
  text-transform: none;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.section-title--big:before {
  display: none;
}

.login-page__background-color {
  background: linear-gradient(#000000 10%, #ffffff);
}

.ict-project-style__button {
  border: 0;
  outline: 0;
  background: none;
  box-sizing: border-box;
  margin: 0px 12px 0px 0px;
  margin: 0 0.75rem 0 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  padding: 0.375rem 1.5rem 0.375rem 1.5rem;
  font-weight: 300;
  background: #1D1D1B;
  color: #FFFFFF;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.ict-project-style__button--save {
  margin: 0.25em !important;
  background: #50E3C2 !important;
  color: #1D1D1B !important;
}
.ict-project-style__button--save:hover {
  background: #1D1D1B !important;
  color: #FFFFFF !important;
}
.ict-project-style__button--submit {
  margin: 0.25em !important;
  background: #FF203C !important;
  color: #1D1D1B !important;
}
.ict-project-style__button--submit:hover {
  background: #1D1D1B !important;
  color: #FFFFFF !important;
}
.ict-project-style__close-project {
  text-align: left;
  color: black;
  display: inline-block;
}
.ict-project-style__divider-section {
  display: inline-block;
  background: #010c1b;
  color: white;
  width: 100%;
  padding-left: -30px;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  border: 1px solid #4fe4bd;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: -8px;
}
.ict-project-style__divider-title-section {
  font-family: "Oswald", sans-serif;
  display: inline-block;
  margin: 10px;
  color: white;
  font-weight: bold;
  float: left;
}
.ict-project-style__divider-chevron-section {
  display: inline-block;
  text-align: right;
  color: #50E3C2;
  font-size: 30px;
  float: right;
  margin-top: 5px;
}
.ict-project-style__title-field {
  color: #010916;
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  width: 100%;
  display: block;
}
.ict-project-style__field-style {
  margin-bottom: 10px !important;
}
.ict-project-style__description-field {
  font-size: 15px;
  font-family: "Garamond";
  color: #010916;
}
.ict-project-style__line-height-description {
  line-height: 1.5;
}
.ict-project-style__section-title {
  font-family: "Helvetica";
  color: #010916;
  font-size: 18px;
  margin-top: 20px;
}
.ict-project-style__icon-field {
  margin-top: -30px;
  font-size: 20px;
  float: right;
  display: block;
  color: black;
}
.ict-project-style__title-style {
  color: black;
  font-size: 15px;
  width: 80%;
  display: inline-block;
  font-family: 'Helvetica';
  margin: 0 auto;
  display: table;
  text-align: justify;
  padding-bottom: 20px;
  padding-top: 20px;
}
.ict-project-style__title-header {
  background: white;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  margin-top: -100px;
}
.ict-project-style__errors-form-style {
  color: red;
  display: block;
  text-align: center;
  padding-bottom: 10px;
}
.ict-project-style__block {
  display: block;
}
.ict-project-style__field {
  float: left;
  width: 48%;
  color: black;
}
@media (max-width: 89.99em) {
  .ict-project-style__field {
    float: none;
    width: 100%;
  }
}
.ict-project-style__field--left {
  margin-right: 40px;
}
@media (max-width: 89.99em) {
  .ict-project-style__field--left {
    margin-right: 0px;
  }
}
.ict-project-style__field--keywords {
  margin-top: -275px;
}
@media (max-width: 89.99em) {
  .ict-project-style__field--keywords {
    margin-top: 0px;
  }
}
.ict-project-style__error {
  color: red;
}

h1, h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  color: white;
}

.home .main {
  margin-top: 0;
  padding-top: 0;
}
@media (max-width: 60.74em) {
  .home .main {
    margin-top: 0;
  }
}
.home__menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  position: relative;
  background: white;
  width: 100%;
  padding-left: 72px;
  padding-left: 4.5rem;
  padding-right: 72px;
  padding-right: 4.5rem;
  padding-top: 48px;
  padding-top: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  transition: width 0.5s ease-in-out;
}
@media (max-width: 60.74em) {
  .home__menu {
    display: none;
  }
}
.home__menu--with-shutter {
  width: 100%;
}
.home__shutter {
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  padding: 48px 0px 0px 0px;
  padding: 3rem 0 0 0;
  transform: scaleY(0);
  transition: transform 0.35s ease-in-out;
  transition-delay: 0.15s;
  transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
}
.home__shutter.active {
  transform: scaleY(1);
  transition-delay: 0s;
}
.home__shutter.active .home__shutter-content {
  opacity: 1;
  transition-delay: 0.35s;
}
.home__shutter-content {
  padding: 0px 168px;
  padding: 0 10.5rem;
  transition: opacity 0.15s ease-in-out;
  opacity: 0;
  transition-delay: 0s;
}
@media (max-width: 89.99em) {
  .home__shutter-content {
    padding: 0px 72px;
    padding: 0 4.5rem;
  }
}
@media (max-width: 73.24em) {
  .home__shutter-content {
    padding: 0px 24px;
    padding: 0 1.5rem;
  }
}
.home__shutter-content .col-xs-3:nth-child(n+5):before {
  content: "";
  display: block;
  background: #979797;
  height: 1px;
  width: 100%;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: 72px;
  margin-top: 4.5rem;
}
.home__shutter-item {
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 2;
}
.home__shutter-item > h2 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  margin: 0px 0px 24px 0px;
  margin: 0 0 1.5rem 0;
  position: relative;
  z-index: 3;
}
.home__shutter-item > p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  margin: 0px;
  margin: 0;
}
.home__shutter-item > a {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 12px;
  line-height: 0.75rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
  margin: 0px;
  margin: 0;
  text-decoration: underline;
  position: relative;
  z-index: 3;
}
.home__shutter-item:before {
  content: "";
  display: block;
  position: absolute;
  background: transparent;
  top: -10%;
  left: -5%;
  width: 110%;
  height: 120%;
  z-index: 1;
  transition: all 0.25s ease-in-out;
}
.home__shutter-item:hover:before {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.17);
}
.sidebar .home__shutter-item > h2 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  margin: 0px 0px 0px 0px;
  margin: 0 0 0 0;
}
.sidebar .home__shutter-item > p {
  display: none;
}
.home__shutter-item--button {
  border: 2px solid black;
  text-align: center;
  padding: 48px;
  padding: 3rem;
}
.home__shutter-item--button:before {
  display: none;
}
.home__shutter-item--button > h2 {
  margin: 0;
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 28.8px;
  line-height: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.home__menu-item {
  display: inline-block;
  border-top: 1px solid #DCDCDC;
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.home__menu-item > a {
  padding-left: 24px;
  padding-left: 1.5rem;
  padding-right: 24px;
  padding-right: 1.5rem;
  padding-top: 24px;
  padding-top: 1.5rem;
  display: block;
  position: relative;
}
.home__menu-item > a:after {
  content: '';
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -9px;
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border-left: 1px solid #DCDCDC;
  border-top: 1px solid #DCDCDC;
  transform: scaleY(0) rotate(45deg);
  transition: all 0.25s ease-in-out;
}
.home__menu-item > a.active:after {
  transform: scaleY(1) rotate(45deg);
}
.home .lSAction > a {
  background: transparent;
}
.home .lSAction > a:before {
  color: #F2EDDC;
  font-size: 40px;
}

.home-mobile-nav {
  display: none;
  background: #50E3C2;
  border-top: 1px solid #1D1D1B;
}
@media (max-width: 60.74em) {
  .home-mobile-nav {
    display: block;
  }
}
@media (max-width: 46.99em) {
  .home-mobile-nav {
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}

.home-mobile-subnav {
  display: none;
}
@media (max-width: 46.99em) {
  .home-mobile-subnav {
    display: block;
    margin: 0px 0px 24px 0px;
    margin: 0 0 1.5rem 0;
  }
}

.vertigopage {
  color: #C3C3C3;
}
.vertigopage__selected {
  color: #50E3C2;
}
.vertigopage__unselected {
  color: #C3C3C3;
}
.vertigopage__section-title {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: #50E3C2;
  display: inline-block;
  line-height: 1.5em;
  margin-top: 120px;
  margin-top: 7.5rem;
  margin-right: 60px;
  margin-left: 60px;
}
.vertigopage__button {
  background: none;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  margin-right: 30px;
  margin-left: 30px;
  padding: 0;
  width: 280px;
  margin-bottom: 0px;
}
.vertigopage__button:hover {
  background: none;
  color: #50E3C2;
}
.vertigopage__visible {
  display: block;
}
.vertigopage__invisible {
  display: none;
}
.vertigopage__space--white {
  margin: 1rem 0 0 0;
}
.vertigopage__header-image {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  display: block;
  height: 200px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}
.vertigopage__header-image span {
  font-family: "Oswald", sans-serif;
  position: relative;
  margin: 0 auto;
  width: 100%;
  top: 50px;
  color: white;
  font-size: 50px;
  text-align: center;
}
.vertigopage__margin-collapse {
  margin-bottom: 0px;
}
.vertigopage__header-text {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 5%;
  margin-top: 2%;
  color: #FFFFFF;
  line-height: 250%;
}
.vertigopage__image {
  margin: 0;
  padding: 0;
  width: 90%;
  position: relative;
  overflow: hidden;
  padding-bottom: 66.97892%;
  position: relative;
}
.vertigopage__image > :first-child {
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.vertigopage__image img {
  display: block;
  width: 100%;
  max-width: 100%;
  transition: all 0.5s ease-in-out;
  mix-blend-mode: screen;
}

.lSAction > a {
  background: white;
  width: 45px;
  height: 53px;
  padding: 0;
  text-align: center;
  display: block;
  transform: translate(0, -50%);
}
.lSAction > a:before {
  display: inline-block;
  font-family: 'fontawesome';
  font-size: 20px;
  line-height: 53px;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lSAction > a.lSNext {
  right: 0;
}
.lSAction > a.lSNext:before {
  content: '\f105';
}
.lSAction > a.lSPrev {
  left: 0;
}
.lSAction > a.lSPrev:before {
  content: '\f104';
}

.slick-prev:before {
  content: '\f104';
}

[dir='rtl'] .slick-prev:before {
  content: '\f105';
}

.slick-slide img {
  margin: 0 auto;
}

.slick-slide figure {
  margin: 0 auto;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  z-index: 2;
  width: 45px;
  height: 53px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease;
}

.slick-prev:hover,
.slick-next:hover {
  color: transparent;
  outline: none;
  background: white;
  width: 50px;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
}

.slick-prev:hover:before {
  transform: translateX(5px);
}

.slick-next:hover:before {
  transform: translateX(-5px);
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  transition: all 0.2s ease;
  display: inline-block;
  font-family: 'fontawesome';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0px;
}

[dir='rtl'] .slick-prev {
  right: 0px;
  left: auto;
}

.slick-prev:before {
  content: '\f104';
}

[dir='rtl'] .slick-prev:before {
  content: '\f105';
}

.slick-next {
  right: 0px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: 0px;
}

.slick-next:before {
  content: '\f105';
}

[dir='rtl'] .slick-next:before {
  content: "";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

.lSAction > a {
  opacity: 1;
  transition: all 0.15s ease-in-out;
}
.lSAction > a:hover {
  width: 60px;
}

.lSAction > a {
  background: transparent;
}
.lSAction > a:before {
  color: #F2EDDC;
  font-size: 40px;
}
.lSAction > a.lSPrev {
  background-color: #50E3C2;
  background-blend-mode: multiply;
}
.lSAction > a.lSNext {
  background-color: #50E3C2;
  background-blend-mode: multiply;
}

.pushy {
  width: 450px;
  background: #FFFFFF;
  border-right: 1px solid #979797;
}
@media (max-width: 46.99em) {
  .pushy {
    width: 300px;
  }
}
.pushy a {
  padding: 0;
}
.pushy ul:first-child {
  margin-top: 0;
}

.pushy-left {
  transform: translate3d(-450px, 0, 0);
}
@media (max-width: 46.99em) {
  .pushy-left {
    transform: translate3d(-300px, 0, 0);
  }
}

.pushy-open-left #container,
.pushy-open-left .push {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transform: translate3d(450px, 0, 0);
}
@media (max-width: 46.99em) {
  .pushy-open-left #container,
  .pushy-open-left .push {
    transform: translate3d(300px, 0, 0);
  }
}

.menu-btn-responsive {
  cursor: pointer;
}

.menu-btn {
  position: relative;
  width: 40px;
  cursor: pointer;
}
@media (max-width: 60.74em) {
  .menu-btn {
    height: 70px;
  }
}
@media (max-width: 46.99em) {
  .menu-btn {
    height: 70px;
  }
}
.pushy-open-left .menu-btn span:before, .pushy-open-left .menu-btn span:after {
  opacity: 0;
}
.pushy-open-left .menu-btn span.first {
  transform: rotate(45deg);
  top: 33px;
}
.pushy-open-left .menu-btn span.second {
  transform: rotate(-45deg);
  top: 33px;
}
.menu-btn span {
  display: block;
  width: 40px;
  height: 2px;
  background: black;
  position: relative;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
}
.menu-btn span:before {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: black;
  position: absolute;
  left: 0;
  top: -4px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.menu-btn span:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: black;
  position: absolute;
  left: 0;
  bottom: -4px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.menu-btn span.first {
  top: 27px;
}
.menu-btn span.second {
  top: 39px;
}

.audiojs {
  background: white;
  position: relative;
  box-shadow: none;
  width: 100%;
  height: 123px;
  border: 1px solid #E6E6E6;
}
.audiojs .play-pause {
  position: absolute;
  width: 56px;
  height: 56px;
  border-right: 0;
  top: 30px;
  left: 30px;
  border-radius: 56px;
  background: #1D1D1B;
  padding: 0;
}
.audiojs .scrubber {
  width: 100%;
  height: 7px;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #EBEBEB;
  border: 0;
}
.audiojs .title {
  position: absolute;
  height: 56px;
  left: 116px;
  top: 30px;
  /*@include font-size(m);
  @include typeface(serif);
  font-weight: weight(bold);*/
  font-size: 16px;
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
  color: #000F24;
}
.audiojs .title span {
  /*@include font-size(m);
  @include typeface(serif);
  font-weight: weight(regular);
  font-style: italic;*/
  font-size: 14px;
  font-size: 0.875rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}
.audiojs .time {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  font-size: 0.75rem;
  font-family: "Merriweather", serif;
  font-weight: 300;
  text-shadow: none;
  color: #1D1D1B;
  border: 0;
}
.audiojs .time .played {
  color: #1D1D1B;
}
.audiojs .progress {
  background: #1D1D1B;
}
.audiojs .loaded {
  background: #EBEBEB;
}
.audiojs .play {
  width: 56px;
  height: 56px;
  position: relative;
  background: 0;
}
.audiojs .play:after {
  position: absolute;
  top: 0;
  left: 3px;
  width: 100%;
  height: 100%;
  content: "\f04b";
  font-family: FontAwesome;
  color: white;
  font-size: 30px;
  display: block;
  text-align: center;
  line-height: 56px;
}
.audiojs .pause {
  width: 56px;
  height: 56px;
  position: relative;
  background: 0;
}
.audiojs .pause:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "\f04c";
  font-family: FontAwesome;
  color: white;
  font-size: 30px;
  display: block;
  text-align: center;
  line-height: 56px;
}
.audiojs .loading {
  width: 56px;
  height: 56px;
  position: relative;
  background: 0;
}
.audiojs .loading:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "\f110";
  font-family: FontAwesome;
  color: white;
  font-size: 30px;
  display: block;
  text-align: center;
  line-height: 56px;
}

.video-js {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  font-size: 10px;
  /* The main font color changes the ICON COLORS as well as the text */
  color: #50E3C2;
}

/* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/
.vjs-ircam-skin .vjs-big-play-button {
  /* The font size is what makes the big play button...big.
     All width/height values use ems, which are a multiple of the font size.
     If the .video-js font-size is 10px, then 3em equals 30px.*/
  font-size: 8em;
  /* We're using SCSS vars here because the values are used in multiple places.
     Now that font size is set, the following em values will be a multiple of the
     new font size. If the font-size is 3em (30px), then setting any of
     the following values to 3em would equal 30px. 3 * font-size. */
  /* 1.5em = 45px default */
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  /* 0.06666em = 2px default */
  border: 0;
  /* 0.3em = 9px default */
  border-radius: 0.3em;
  /* Align center */
  left: 50%;
  top: 50%;
  margin-left: -1.5em;
  margin-top: -0.75em;
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: #2B333F;
  /* Opacity: 1.0 = 100%, 0.0 = 0% */
  background-color: rgba(43, 51, 63, 0.7);
  background-color: transparent;
}

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  height: 4px;
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: #50E3C2;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
  /* For IE8 we'll lighten the color */
  background: ligthen(#73859f, 25%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(115, 133, 159, 0.5);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
  /* For IE8 we'll lighten the color */
  background: ligthen(#73859f, 50%);
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(115, 133, 159, 0.75);
}

.vjs-ircam-skin .vjs-poster {
  outline: none;
  outline: 0;
}

.vjs-ircam-skin:hover .vjs-big-play-button {
  background-color: transparent;
}

.vjs-ircam-skin .vjs-fullscreen-control:before, .vjs-ircam-skin.vjs-fullscreen .vjs-fullscreen-control:before {
  content: '';
}

.vjs-ircam-skin.vjs-fullscreen .vjs-fullscreen-control {
  background: #fff;
}

.vjs-ircam-skin .vjs-fullscreen-control {
  border: 3px solid #50E3C2;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: -7px;
  top: 50%;
  height: 14px;
  width: 22px;
  margin-right: 10px;
}

.vjs-ircam-skin.vjs-fullscreen .vjs-fullscreen-control:after {
  background: #000;
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 5px;
}

.vjs-ircam-skin .vjs-progress-holder {
  margin: 0;
}

.vjs-ircam-skin .vjs-progress-control .vjs-progress-holder:after {
  border-radius: 2px;
  display: block;
  height: 4px;
}

.vjs-ircam-skin .vjs-progress-control .vjs-load-progres, .vjs-ircam-skin .vjs-progress-control .vjs-play-progress {
  border-radius: 2px;
  height: 4px;
}

.vjs-ircam-skin .vjs-playback-rate {
  display: none;
}

.vjs-ircam-skin .vjs-progress-control {
  margin-right: 40px;
}

.vjs-ircam-skin .vjs-time-control {
  right: 40px;
}

.vjs-ircam-skin .vjs-mute-control:before, .vjs-ircam-skin .vjs-volume-menu-button:before, .vjs-ircam-skin .vjs-mute-control.vjs-vol-0:before, .vjs-ircam-skin .vjs-volume-menu-button.vjs-vol-0:before, .vjs-ircam-skin .vjs-mute-control.vjs-vol-1:before, .vjs-ircam-skin .vjs-volume-menu-button.vjs-vol-1:before, .vjs-ircam-skin .vjs-mute-control.vjs-vol-2:before, .vjs-ircam-skin .vjs-volume-menu-button.vjs-vol-2:before {
  content: '';
}

.vjs-ircam-skin .vjs-menu-button-inline .vjs-menu, .vjs-ircam-skin .vjs-menu-button-inline:focus .vjs-menu, .vjs-ircam-skin .vjs-menu-button-inline.vjs-slider-active .vjs-menu {
  display: block;
  opacity: 1;
}

.vjs-ircam-skin .vjs-volume-menu-button {
  width: 3em;
  position: absolute;
  right: 0;
  margin-right: 40px;
}

.vjs-ircam-skin .vjs-menu-button .vjs-menu-content, .vjs-ircam-skin .vjs-menu-button-inline:hover, .vjs-ircam-skin .vjs-menu-button-inline:focus, .vjs-ircam-skin .vjs-menu-button-inline.vjs-slider-active {
  width: 3em;
}

.vjs-ircam-skin .vjs-menu-button-inline .vjs-menu {
  left: 0;
}

.vjs-ircam-skin .vjs-mouse-display:before, .vjs-ircam-skin .vjs-play-progress:before, .vjs-ircam-skin .vjs-volume-level:before {
  content: '';
}

.vjs-ircam-skin .vjs-volume-bar {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAcCAYAAAAa/l2sAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxMzU4NkFCRTZFMDExMUU2ODQyMjg1MjAwM0FFNjlBMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxMzU4NkFCRjZFMDExMUU2ODQyMjg1MjAwM0FFNjlBMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAwNDYyQUZGNkUwMTExRTY4NDIyODUyMDAzQUU2OUEzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAwNDYyQjAwNkUwMTExRTY4NDIyODUyMDAzQUU2OUEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+/U1vZAAAAFxJREFUeNpi/PWCYUABq/h/FP7vl4y++NQzMQwxMOrgUQcPdQez0MEOXwLym0eTxKiDRx08zEoJqub60SQx6uBRBw+BUmJAc/1okhh18KiDRx086uBRB9MUAAQYAFK9CG1Nh9VWAAAAAElFTkSuQmCC");
  background-size: 22px 14px;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  max-width: 22px;
  max-height: 14px;
  margin: 7px 4px;
  border-radius: 0;
}

.vjs-ircam-skin .vjs-volume-level {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAcCAYAAAAa/l2sAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyppVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowMDQ2MkFGOTZFMDExMUU2ODQyMjg1MjAwM0FFNjlBMyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowMDQ2MkFGQTZFMDExMUU2ODQyMjg1MjAwM0FFNjlBMyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAwNDYyQUY3NkUwMTExRTY4NDIyODUyMDAzQUU2OUEzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAwNDYyQUY4NkUwMTExRTY4NDIyODUyMDAzQUU2OUEzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+L58+xQAAAFtJREFUeNrs1DsOABAQRVEjrMH+d8cWKEatofHJyH2dkDjFm5Ga3dPEpMO5FdHZe++MBTBg6+Bw4Q9d3AuVAAz4sy2xdeqpBGDABrbE06mnEoABAwYM+Gi6AAMApVAJH04fDl8AAAAASUVORK5CYII=");
  background-size: 22px 14px;
  background-repeat: no-repeat;
  max-width: 22px;
  max-height: 14px;
  height: 100%;
}

/*# sourceMappingURL=index.min.css.map */
